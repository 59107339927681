import React from "react";
import "./styles.scss";
import reloj from "../../../../assets/icons/reloj-icon.png";
import calendario from "../../../../assets/calendar-icon.png";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const CardItem = (props) => {
  const { id, career, price, current_quota, start_date, schedule } = props.item;

  const amount = (price / career.duration).toFixed(0);

  return (
    <Card className="itemCard">
      <Card.Header className="item-card-header">
        Aprovechá la Becademy
      </Card.Header>
      <section className="item-card-test-class">
        <h6 className="item-card-test-class-title">{`${career.duration} Meses`}</h6>
      </section>
      <Card.Body className="item-card-body">
        <section className="item-card-title-container">
          <h1 className="item-card-title">{career.name}</h1>
        </section>
        <div className="item-card-inner-container">
          <section className="item-card-disc-container">
            <div className="item-card-start-date-container">
              <img src={calendario} alt="" className="item-card-img" />
              <div className="item-card-start-date">
                Fecha de inicio:
                <strong className="item-card-start-date-strong">
                  {start_date}
                </strong>
              </div>
            </div>
            <div className="item-card-duration-month-container">
              <img src={reloj} alt="" className="item-card-img-calendar" />
              <div>
                Horario: <span>{`${schedule} `}</span>
              </div>
            </div>
          </section>
          <section className="item-card-becademy-container">
            <div
              className={
                current_quota > 0
                  ? "item-card-quota-available"
                  : "item-card-quota-none"
              }
            >{`Cupos disponibles: ${current_quota}`}</div>
            <div className="item-card-becademy">{`Becademy $${price}  ARS`}</div>
          </section>
          <section className="item-card-price-container">
            <div className="item-card-dues-price">
              <div className="item-card-dues">
                {`Hasta en ${career.duration} de cuotas de`}
              </div>
              <div className="item-card-price-amount">{`$${amount} ARS`}</div>
            </div>
          </section>
        </div>
      </Card.Body>
      <Card.Footer className="item-card-footer">
        <Link to={`/product/detail/${id}`} className="item-card-button-link">
          <button className="item-card-button">Ver detalle</button>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default CardItem;
