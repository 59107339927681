/* Gateway */
import { HttpStudentLoginGateway } from "../gateways/HttpStudentLoginGateway";
import { HttpSellersLoginGateway } from "../gateways/HttpSellersLoginGateway";
import { HttpStudentUpdateGateway } from "../gateways/HttpStudentUpdateGateway";

/* Presenter */
import { StudentLoginPresenter } from "./StudentLoginPresenter";
import { SellersLoginPresenter } from "./SellersLoginPresenter";
import { OnboardingPresenter } from "./OnboardingPresenter";

export const getStudentLoginPresenter = (viewHandlers) => {
  const getLoginGateway = HttpStudentLoginGateway();
  return StudentLoginPresenter(getLoginGateway, viewHandlers);
};

export const getSellersLoginPresenter = (viewHandlers) => {
  const getSellersLoginGateway = HttpSellersLoginGateway();
  return SellersLoginPresenter(getSellersLoginGateway, viewHandlers);
};

export const getOnboardingPresenter = (viewHandlers) => {
  const getStudentUpdateGateway = HttpStudentUpdateGateway();
  return OnboardingPresenter(getStudentUpdateGateway, viewHandlers);
};
