export const StudentCreatePresenter = (createStudentGateway, viewHandlers) => {
  return {
    present() {
      console.log("StudentCreate was rendered!");
    },
    createStudent(data) {
      const studentCreatePromise = createStudentGateway.createStudent(data);

      studentCreatePromise
        .then((res) => {
          viewHandlers.createStudentSuccess(res);
        })
        .catch((err) => {
          viewHandlers.createStudentError(err);
        });
    },
  };
};
