import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ handleLogin, loading, hasLoginError }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const handleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show);
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const onSubmit = (data) => {
    handleLogin(data);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (hasLoginError) {
      setError("email", {
        type: "validate",
      });
      setError("password", {
        type: "validate",
      });
    }
  }, [hasLoginError, setError]);

  return (
    <div className="login-form-main-container">
      <Container fluid className="login-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmit)} className="login-form">
          <Row className="login-form-grid-row">
            <Form.Group
              as={Col}
              className="login-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="input-label">Email</Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-5 mx-auto">
                    <Form.Control
                      {...field}
                      type="email"
                      placeholder="username@mail.com"
                      isInvalid={errors.email}
                      className="login-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.email?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  El campo email es obligatorio
                </Stack>
              )}
              {errors.email?.type === "validate" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  El valor ingresado es incorrecto
                </Stack>
              )}
            </Form.Group>
          </Row>
          <Row className="login-form-grid-row">
            <Form.Group
              as={Col}
              className="login-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="input-label">Password</Form.Label>
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 8 }}
                render={({ field }) => (
                  <Stack
                    direction="horizontal"
                    className="col-md-5 mx-auto"
                    gap={1}
                  >
                    <Form.Control
                      {...field}
                      type={inputType}
                      placeholder="********"
                      isInvalid={errors.password}
                      className="login-form-input"
                    />
                    <button
                      onClick={handleShowPassword}
                      variant="outline-secondary"
                      id="button-addon1"
                      className="psw-button"
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          color="#FFFF"
                          size="2x"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEye} color="#FFFF" size="2x" />
                      )}
                    </button>
                  </Stack>
                )}
              />
              {errors.password?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  El campo password es obligatorio
                </Stack>
              )}
              {errors.password?.type === "minLength" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  La contraseña debe tener al menos 8 caracteres
                </Stack>
              )}
              {errors.password?.type === "validate" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  El valor ingresado no es válido
                </Stack>
              )}
            </Form.Group>
          </Row>
          <div className="submit-button-container">
            <button
              type="button"
              className="back-button"
              disabled={loading}
              onClick={goBack}
            >
              Volver
            </button>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Cargando..." : "Ingresar"}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default LoginForm;
