import axios from "axios";

export const HttpCreateSaleGateway = () => {
  return {
    createSale: async (data) => {
      const {
        course_id,
        document_number,
        email,
        pay_first_installment,
        referral_code,
        uses_tools,
      } = data;
      const body = {
        course_id: Number(course_id),
        document_number,
        email,
        pay_first_installment,
        referral_code,
        uses_tools,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/sale/create`,
        body
      );
    },
  };
};
