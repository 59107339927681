export const CourseUpdatePresenter = (
  getCourseGateway,
  updateCourseGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("CourseEdit was rendered!");
    },
    getCourseToUpd(id) {
      const getCourseToUpdPromise = getCourseGateway.getCourse(id);

      getCourseToUpdPromise
        .then((data) => {
          viewHandlers.getCourseToUpdSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCourseToUpdError(err);
        });
    },
    updateCourse(data) {
      const updateCoursePromise = updateCourseGateway.updateCourse(data);

      updateCoursePromise
        .then((data) => {
          viewHandlers.updateCourseSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateCourseError(err);
        });
    },
  };
};
