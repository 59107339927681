import axios from "axios";

export const HttpCreateSellerGateway = () => {
  return {
    createSeller: async (data) => {
      const { name, lastname, documentNumber, email, password } = data;
      const body = {
        name,
        lastname,
        documentNumber,
        email,
        password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/seller/create`,
        body
      );
    },
  };
};
