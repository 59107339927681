import React, { useContext } from "react";
import { AccordionContext } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";
import { Card } from "react-bootstrap";
import DropdownIcon from "../../assets/icons/DropdownIcon";
import diploma from "../../assets/diploma.jpg";
import carnet from "../../assets/CARNET.jpg";
import "./styles.scss";

const CustomToggle = ({ children, eventKey, callBack }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callBack && callBack(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className="button-accordion"
      onClick={decoratedOnClick}
    >
      <DropdownIcon showOpen={isCurrentEventKey} />
      {children}
    </button>
  );
};

const AccordionDetails = ({ product }) => {
  return (
    <Accordion className="accordion-details-main-container">
      <Card className="accordion-details-card">
        <Card.Header className="accordion-details-item">
          <h2>Introducción</h2>
          <CustomToggle eventKey="0"></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="accordion-detail-card-body">
            {product.introduction}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/*  */}
      <Card className="accordion-details-card">
        <Card.Header className="accordion-details-item">
          <h2>Requisitos</h2>
          <CustomToggle eventKey="1"></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="accordion-detail-card-body">
            {product.requirements}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/*  */}
      <Card className="accordion-details-card">
        <Card.Header className="accordion-details-item">
          <h2>Certificado</h2>
          <CustomToggle eventKey="2"></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="accordion-detail-card-body">
            <div className="accordion-detail-card-body-img-container">
              <img src={diploma} alt="" />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/*  */}
      <Card className="accordion-details-card">
        <Card.Header className="accordion-details-item">
          <h2>Carnet</h2>
          <CustomToggle eventKey="3"></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body className="accordion-detail-card-body">
            <div className="accordion-detail-card-body-img-container">
              <img src={carnet} alt="" />
            </div>
            <div className="accordion-detail-card-body-detail">
              Con tu carnet podés presentarte en cualquier barbería y pedir
              trabajo. Escaneando tu código QR salís en nuestra página con tu
              diploma y estudios realizados.
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionDetails;
