export const SellerUpdatePresenter = (
  getSellerGateway,
  updateSellerGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("SellerEdit was rendered!");
    },
    getSellerToUpd(id) {
      const getSellerToUpdPromise = getSellerGateway.getSeller(id);

      getSellerToUpdPromise
        .then((data) => {
          viewHandlers.getSellerToUpdSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getSellerToUpdError(err);
        });
    },
    updateSeller(data) {
      const updateSellerPromise = updateSellerGateway.updateSeller(data);

      updateSellerPromise
        .then((data) => {
          viewHandlers.updateSellerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateSellerError(err);
        });
    },
  };
};
