import React, { useEffect } from "react";
import "./styles.scss";
import Swal from "sweetalert2/src/sweetalert2.js";
import logo from "../../../../assets/logo_200px.png";
import { useNavigate } from "react-router-dom";

const SaleStatus = ({ paymentStatus }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("status: ", paymentStatus);
    if (paymentStatus === "success") {
      <div className="success-sale-container">
        {Swal.fire({
          title: "¡Gracias por tu compra!",
          icon: "success",
          imageUrl: logo,
          imageAlt: "logo-tb",
          text: `Tu pago fue acreditado con éxito`,
          confirmButtonText: "Excelente",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            title: "success-sale-title",
            icon: "success-sale-icon",
            image: "success-sale-image",
            htmlContainer: "success-sale-text",
            confirmButton: "success-sale-button",
          },
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/");
          }
        })}
      </div>;
    } else if (paymentStatus === "pending") {
      Swal.fire({
        title: "Pago pendiente",
        icon: "warning",
        imageUrl: logo,
        imageAlt: "logo-tb",
        text: "Su pago todavía no pudo ser procesado",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          title: "pending-sale-title",
          icon: "pending-sale-icon",
          image: "pending-sale-image",
          htmlContainer: "pending-sale-text",
          confirmButton: "pending-sale-button",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          navigate("/");
        }
      });
    } else if (paymentStatus === "failure") {
      Swal.fire({
        title: "Pago fallido",
        icon: "error",
        imageUrl: logo,
        imageAlt: "logo-tb",
        text: "Su pago no ha podido ser efectuado",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          title: "failure-sale-title",
          icon: "failure-sale-icon",
          image: "failure-sale-image",
          htmlContainer: "failure-sale-text",
          confirmButton: "failure-sale-button",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          navigate("/");
        }
      });
    }
  });

  return (
    <div className="sale-status-main-container">
      <div className="sale-status-inner-container">
      </div>
    </div>
  );
};

export default SaleStatus;
