import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../../context/CartContext";
import "./Cart.scss";

const Cart = () => {
  const navigate = useNavigate();
  const { cartItems, clearCart } = useContext(CartContext);
  const [item, setItem] = useState({});

  useEffect(() => {
    setItem(cartItems[0].item);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyCancel = () => {
    clearCart();
    navigate("/");
  };

  const confirmSale = () => {
    /* navigate("/sale", { state: { itemData: item } }); */
    navigate("/sale");
  };

  return (
    <>
      <div className="cart-main-container">
        <h1>Su pedido</h1>
        <div className="cart-container">
          <div className="cart-item">
            <h4 className="cart-item-title">{item.name}</h4>
          </div>
          <div className="cart-discount">
            <label>Código de descuento:</label>
            <input type="text" className="cart-inputText" id="discount" />
          </div>
          <div className="cart-myTools">
            <input
              type="checkbox"
              className="cart-myTools-input"
              id="myTools"
            />
            <label>Uso mis herramientas</label>
          </div>
          <div className="cart-total">
            <h2>TOTAL: {item.price}</h2>
          </div>
          <div className="cart-button-buy-container">
            <button onClick={confirmSale}>IR A COMPRAR</button>
          </div>
          <div className="cart-button-buy-container">
            <button onClick={buyCancel}>Cancelar compra</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
