import React, { useEffect } from "react";
import "./styles.scss";
import SellerForm from "./../../components/SellerForm/index";
import { getSellerCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const SellerCreate = () => {
  const viewHandlers = {
    createSellerSuccess(data) {
      console.log("<<<<<<SELLER CREATE>>>>>>>", data.data.message);
    },
    createSellerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellerCreatePresenter(viewHandlers);

  const createNewSeller = (data) => {
    presenter.createSeller(data);
  };

  useEffect(() => {
    presenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="seller-create-main-container">
      <div className="seller-create-title-container">
        <div className="seller-create-title">Registrar Vendedor</div>
      </div>
      <hr className="divider" />
      <div className="seller-form-container">
        <SellerForm create={createNewSeller} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default SellerCreate;
