import React from "react";
import { G } from "@react-pdf/renderer";
import TBEnseña from "./../../../../assets/tb-enseña-PhotoRoom (2).png";
import gorroTb from "./../../../../assets/tb-gorro-blanco (1).png";
import profile from "./../../../../assets/profile-image.jpg";
import {
  Defs,
  Document,
  Image,
  Page,
  Rect,
  Svg,
  View,
  Text,
} from "@react-pdf/renderer";
import { styles } from "./styles";

const CarnetPDFBackground = () => {
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        zIndex: -999,
        position: "absolute",
      }}
    >
      <Svg style={{ width: "100%", height: "100%" }}>
        <Defs>
          <G id="bg" patternUnits="userSpaceOnUse" width="100" height="100">
            <Image src={gorroTb} />
          </G>
        </Defs>
        <Rect width="100%" height="100%" fill="url(#bg)" />
      </Svg>
    </View>
  );
};

const CarnetPDF = ({ id, name, lastname, list }) => {
  return (
    <Document>
      <Page
        style={styles.body}
        orientation={"landscape"}
        size="b8"
        wrap={false}
      >
        <View style={styles.carnetPDFHeaderContainer}>
          <CarnetPDFBackground />
          <View style={styles.carnetPDFTitleContainer}>
            <Text style={styles.carnetPdfHeaderTitle}>
              CARNET DE ESTUDIOS ACADÉMICOS
            </Text>
          </View>
          <View style={styles.carnetPDFSubtitleContainer}>
            <Text style={styles.carnetPdfHeaderSubtitle}>
              REGISTRO DE EGRESADOS
            </Text>
          </View>
        </View>
        {/* BODY */}
        <View style={styles.carnetPDFBodyContainer}>
          <Image
            src={TBEnseña}
            style={{
              position: "absolute",
              top: 29,
              left: 28,
              width: "100%",
              height: "100%",
              opacity: 0.1,
            }}
          />
          <View style={styles.carnetPDFStudentContainer}>
            <View style={styles.carnetPDFStudentDivisor}>
              {/*mitad 1 */}
              <View style={styles.carnetPDFStudentDataContainer}>
                {/* View foto */}
                <View style={styles.carnetPDFStudentImageContainer}>
                  <Image src={profile} style={styles.carnetPDFProfileImage} />
                </View>
                {/* view data */}
                <View style={styles.carnetPDFStudentPersonalDataContainer}>
                  <Text style={styles.carnetPDFStudentPersonalDataLabel}>
                    {`N° Matrícula: `}
                    <Text style={styles.carnetPDFStudentPersonalDataContent}>
                      {id}
                    </Text>
                  </Text>
                  <Text style={styles.carnetPDFStudentPersonalDataLabel}>
                    {`Apellido: `}
                    <Text style={styles.carnetPDFStudentPersonalDataContent}>
                      {lastname}
                    </Text>
                  </Text>
                  <Text style={styles.carnetPDFStudentPersonalDataLabel}>
                    {`Nombre: `}
                    <Text style={styles.carnetPDFStudentPersonalDataContent}>
                      {name}
                    </Text>
                  </Text>
                </View>
              </View>
              {/*mitad 2 */}
              <View style={styles.carnetPDFStudentCoursesContainer}>
                <View style={styles.carnetPDFStudentCoursesLabel}>
                  <Text>{`Listado de cursos finalizados:`}</Text>
                </View>
                <View style={styles.carnetPDFStudentCoursesData}>
                  {list.map((item, index) => {
                    return <Text key={index}>{item.name}</Text>;
                  })}
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.carnetPDFFooterContainer}>
          <View style={styles.carnetPDFFooterTitleContainer}>
            <Text style={styles.carnetPdfFooterTitle}>
              CARNET DE VALIDACIÓN TAXIBARBER ENSEÑA
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CarnetPDF;
