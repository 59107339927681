import { HttpGetAllCareersGateway } from "../gateways/HttpGetAllCareersGateway";
import { HttpGetAllCoursesGateway } from "../gateways/HttpGetAllCoursesGateway";
import { HttpGetCourseGateway } from "../gateways/HttpGetCourseGateway";
import { HttpGetCareerGateway } from "../gateways/HttpGetCareerGateway";

import { ProductsPresenter } from "./ProductsPresenter";
import { ProductsDetailPresenter } from "./ProductsDetailPresenter";

export const getProductsPresenter = (viewHandlers) => {
  const getHttpGetAllCareersGateway = HttpGetAllCareersGateway();
  const getHttpGetAllCoursesGateway = HttpGetAllCoursesGateway();
  return ProductsPresenter(
    getHttpGetAllCareersGateway,
    getHttpGetAllCoursesGateway,
    viewHandlers
  );
};

export const getProductsDetailPresenter = (viewHandlers) => {
  const getHttpGetCourseGateway = HttpGetCourseGateway();
  const getHttpGetCareerGateway = HttpGetCareerGateway();
  return ProductsDetailPresenter(
    getHttpGetCourseGateway,
    getHttpGetCareerGateway,
    viewHandlers
  );
};
