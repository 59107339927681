import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../../../context/CartContext";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import LandingButtons from "../../../landing/components/LandingButtons";
import AccordionDetails from "../../../../components/AccordionDetails/index";
import reloj from "../../../../assets/icons/reloj.svg";
import calendario from "../../../../assets/icons/calendario.svg";
import descargar from "../../../../assets/icons/descargar.svg";
import { getProductsDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

const ProductDetail = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [career, setCareer] = useState({});
  const { addItem } = useContext(CartContext);
  const navigate = useNavigate();

  const onAdd = (item) => {
    addItem(item);
    navigate("/sale");
  };

  const programPreview = (program) => {
    const preview = document.createElement("a");
    preview.href = program;
    preview.target = "_blank";
    preview.click();
  };

  const viewHandlers = {
    getCourseSuccess(data) {
      console.log("<<<<<<COURSE DETAIL>>>>>>>", data.statusText);
      setCourse(data.data.data);
      setCareer(data.data.data.career);
    },
    getCourseError(err) {
      console.log("<<<<<<COURSE ERROR>>>>>>>", err);
    },
    getCareerSuccess(data) {
      console.log("<<<<<<CAREER DETAIL>>>>>>>", data.statusText);
    },
    getCareerError(err) {
      console.log("<<<<<<CAREER ERROR>>>>>>>", err);
    },
  };

  const presenter = getProductsDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getCourse(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="product-detail-main-container">
      <LandingButtons />
      <div className="product-detail-title-container">
        <div className="product-detail-index">{`100% Presencial - ${course.schedule} hs`}</div>
        <div className="product-detail-title">{career.name}</div>
      </div>
      <div className="product-detail-disc">
        <ul className="product-detail-disc-list">
          <li className="product-detail-disc-list-item">
            {`Clases de ${course.quota} alumnos (MÁXIMO)`}
          </li>
          <li className="product-detail-disc-list-item">
            Clases prácticas y teóricas
          </li>
          <li className="product-detail-disc-list-item">
            Cursa con nuestras máquinas y herramientas
          </li>
          <li className="product-detail-disc-list-item">
            Aprovechá tu clase de prueba
          </li>
        </ul>
        <div
          className={
            course.current_quota > course.quota / 2
              ? "product-detail-item-quota"
              : "product-detail-item-quota-unavailable"
          }
        >
          {`¡Solo hay ${course.current_quota} lugares disponibles!`}
        </div>
      </div>
      <div className="product-detail-buttons-container">
        <button
          className={"product-detail-add-button"}
          onClick={() => onAdd(course)}
        >
          Inscribirme ahora
        </button>
      </div>
      <div className="product-detail-about">
        <div className="product-detail-about-title-container">
          <div className="product-detail-about-title">Sobre la carrera:</div>
          <div className="product-detail-accordion-container">
            <AccordionDetails product={career} />
          </div>
        </div>
        <div className="product-detail-info-container">
          <div className="product-detail-info-item">
            <div className="product-detail-info-item-img-container">
              <img
                src={reloj}
                alt=""
                className="product-detail-info-item-img"
              />
            </div>
            <div className="product-detail-info-item-description">
              {`Duración: ${career.duration} meses`}
            </div>
          </div>
          {/*  */}
          <div className="product-detail-info-item">
            <div className="product-detail-info-item-img-container">
              <img
                src={calendario}
                alt=""
                className="product-detail-info-item-img"
              />
            </div>
            <div className="product-detail-info-item-description">
              {`Horario: ${course.schedule}`}
            </div>
          </div>
          {/*  */}
          <div
            className="product-detail-info-item-program"
            onClick={() => programPreview(career.program_url)}
          >
            <div className="product-detail-info-item-img-container">
              <img
                src={descargar}
                alt=""
                className="product-detail-info-item-img"
              />
            </div>
            <div className="product-detail-info-item-description">
              Ver programa
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
