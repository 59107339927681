import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../../components/LoginHeader";
import "./styles.scss";

const LoginSelector = () => {
  const navigate = useNavigate();

  const sellersLogin = () => {
    navigate("/login-seller");
  };
  const studentsLogin = () => {
    navigate("/login-student");
  };

  return (
    <div className="login-selector-main-container">
      <div className="login-selector-header-container">
        <LoginHeader />
      </div>
      <div className="login-selector-title-container">
        <h2 className="login-selector-title">Iniciar sesión como</h2>
      </div>
      <div className="login-selector-buttons-container">
        <Container fluid className="login-selector-grid">
          <Row className="login-selector-row">
            <Col
              className="login-selector-column"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <div
                className="login-selector-sellers-button"
                onClick={sellersLogin}
              >
                <div className="login-selector-button-text-container">
                  <h2 className="login-selector-button-text">Vendedor</h2>
                </div>
              </div>
            </Col>
            <Col
              className="login-selector-column"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <div
                className="login-selector-students-button"
                onClick={studentsLogin}
              >
                <div className="login-selector-button-text-container">
                  <h2 className="login-selector-button-text">Estudiante</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LoginSelector;
