export const CoursesHomePresenter = (
  getAllCoursesGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("AllCourses was rendered!");
    },
    getCourses() {
      const getAllCoursesPromise = getAllCoursesGateway.getAllCourses();

      getAllCoursesPromise
        .then((data) => {
          viewHandlers.getCoursesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCoursesError(err);
        });
    },
  };
};
