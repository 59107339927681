import React from "react";
import { styles } from "./styles";
/* import logo from "../../../../assets/icons/tb-enseña.png"; */
import firma_coco from "../../../../assets/firma_coco.png";
import firma_darko from "../../../../assets/firma_darko.png";
import logo from "../../../../assets/tb.png";
import {
  Defs,
  Document,
  Image,
  LinearGradient,
  Page,
  Rect,
  Stop,
  Svg,
  View,
  Text,
} from "@react-pdf/renderer";

const DiplomaPDFBackground = () => {
  return (
    <View
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        zIndex: -999,
        position: "absolute",
      }}
    >
      <Svg style={styles.svg}>
        <Defs>
          <LinearGradient id="myLinearGradient" x1="1" x2="0" y1="0" y2="0.85">
            <Stop offset="5" stopColor="rgb(241,204,44)" />
            <Stop offset="95" stopColor="rgb(255, 255, 255)" />
          </LinearGradient>
        </Defs>
        <Rect width="1000" height="1000" fill="url('#myLinearGradient')" />
      </Svg>
    </View>
  );
};

const DiplomaPDF = ({ name, dni, lastname, career }) => {
  return (
    <Document>
      <Page style={styles.page} size="A4" wrap={false}>
        <DiplomaPDFBackground />
        <View style={styles.diplomaPdfOutterWrapper}>
          <View style={styles.diplomaPdfInnerWrapper}>
            <View style={styles.diplomaPdfImageContainer}>
              <Image src={logo} style={styles.diplomaPdfImage} />
            </View>
            {/*  */}
            <View style={styles.diplomaPdfStudentDataContainer}>
              <View style={styles.diplomaPdfStudentNameContainer}>
                <Text
                  style={styles.diplomaPdfStudentName}
                >{`${name} ${lastname}`}</Text>
              </View>
              <View style={styles.diplomaPdfStudentDniContainer}>
                <Text style={styles.diplomaPdfStudentDni}>{`DNI: ${dni}`}</Text>
              </View>
            </View>
            {/*  */}
            <View style={styles.diplomaPdfStudentStatus}>
              <Text style={styles.diplomaPdfStatus}>Ha finalizado</Text>
            </View>
            {/*  */}
            <View style={styles.diplomaPdfTitleContainer}>
              <Text style={styles.diplomaPdfTitle}>{career}</Text>
            </View>
            <View style={styles.diplomaPdfContentContainer}>
              <Text style={styles.diplomaPdfContent}>
                Cursando presencialmente tanto las actividades teóricas como
                prácticas, con una duración de 120 horas impartidas a lo largo
                de 48 semanas, cumpliendo todos los requisitos y exigencias
                académicas correspondientes
              </Text>
            </View>
            {/*  */}
            <View style={styles.diplomaPdfSignaturesContainer}>
              <View style={styles.diplomaPdfSignature}>
                <View style={styles.diplomaPdfFirstSignature}>
                  <Image src={firma_coco} style={styles.firstSignature} />
                </View>
                <Text style={styles.diplomaPdfSignatureText}>Jorge Ramón</Text>
                <Text style={styles.diplomaPdfSignatureText}>Profesor</Text>
              </View>
              <View style={styles.diplomaPdfSignature}>
                <View style={styles.diplomaPdfSecondSignature}>
                  <Image src={firma_darko} style={styles.secondSignature} />
                </View>
                <Text style={styles.diplomaPdfSignatureText}>Darko Agnic</Text>
                <Text style={styles.diplomaPdfSignatureText}>CEO</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DiplomaPDF;
