import Carousel from "react-bootstrap/Carousel";
import Item1 from "../../../../assets/img1.png";
import Item2 from "../../../../assets/img2.jpeg";
import Item3 from "../../../../assets/img3.jpeg";
import Item4 from "../../../../assets/img4.png";
import "./styles.scss";

const CarouselIntro = () => {
  return (
    <div className="custom-carrusel-main-container">
      <Carousel indicators={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={Item1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Item2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Item3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Item4} alt="Quarter slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselIntro;
