import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CoursesForm from "../../components/CoursesForm";
import { getCourseUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const CourseEdit = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});

  const viewHandlers = {
    getCourseToUpdSuccess(data) {
      console.log("<<<<<<COURSE BY ID>>>>>>>", data.statusText);
      setCourse(data.data.data);
    },
    getCourseToUpdError(err) {
      console.log("<<<<<<COURSE BY ID ERROR>>>>>>>", err);
    },
    updateCourseSuccess(data) {
      console.log("<<<<<<UPDATED COURSE>>>>>>>", data.statusText);
    },
    updateCourseError(err) {
      console.log("<<<<<<UPDATED COURSE ERROR>>>>>>>", err);
    },
  };

  const presenter = getCourseUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getCourseToUpd(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCourse = (data) => {
    presenter.updateCourse({
      ...data,
      quota: Number(course.quota) + (Number(data.quota) - Number(course.current_quota)),
      current_quota: Number(data.quota),
      id: Number(course.id),
      career_id: Number(course.career_id),
    });
  };

  return (
    <div className="course-edit-main-container">
      <div className="course-edit-title-container">
        <div className="course-edit-title">Editar curso</div>
      </div>
      <hr className="divider" />
      <div className="course-edit-form-container">
        <CoursesForm update={updateCourse} course={course} idCourse={id} />
      </div>
    </div>
  );
};

export default CourseEdit;
