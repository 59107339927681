import React, { useEffect } from "react";
import useUser from "../../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import LoginHeader from "../../components/LoginHeader";
import "./styles.scss";
import { getSellersLoginPresenter } from "../../infrastructure/presentation/presenterProvider";

const SellersLogin = () => {
  const { login, isLoading, isLogged, hasLoginError } = useUser();
  const navigate = useNavigate();

  const viewHandlers = {
    loginSellerSuccess(data) {
      console.log("<<<<<<SELLER LOGIN>>>>>>>", data.data.message);
      login(data.data);
    },
    loginSellerError(err) {
      console.log("<<<<<<SELLER LOGIN ERROR>>>>>>>", err);
      login(err);
    },
  };

  const presenter = getSellersLoginPresenter(viewHandlers);

  const handleLogin = (data) => {
    presenter.loginSeller(data);
  };

  useEffect(() => {
    presenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* Esto tiene que llevar a la home del apartado de sellers */
    if (isLogged) navigate("/");
  }, [isLogged, navigate]);

  return (
    <div className="sellers-login-main-container">
      <LoginHeader />
      <hr className="sellers-login-divider" />
      <div className="sellers-login-form-container">
        <div className="sellers-login-title">Login de vendedores</div>
        <LoginForm
          handleLogin={handleLogin}
          loading={isLoading}
          hasLoginError={hasLoginError}
        />
      </div>
      <hr className="sellers-login-divider" />
    </div>
  );
};

export default SellersLogin;
