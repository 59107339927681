import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

const PrivateRoute = ({ Component, roles }) => {
  const { user, verifySessionStatus } = useUser();

  useEffect(() => {
    if (!verifySessionStatus) {
      <Navigate to="/" />;
    }
  }, [verifySessionStatus]);

  if (!user) {
    return <Navigate to="/" />;
  } else if (roles && roles.indexOf(user.role) === -1) {
    return <Navigate to={{ pathname: "/" }} />;
  } else {
    return <Component />;
  }
};

export default PrivateRoute;
