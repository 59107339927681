export const SellerProfilePresenter = (
  getInscriptionsGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Seller profile was rendered!");
    },
    getAllInscriptions(data) {
      const getInscriptionsPromise =
        getInscriptionsGateway.getInscriptions(data);

      getInscriptionsPromise
        .then((data) => {
          viewHandlers.getInscriptionsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getInscriptionsError(err);
        });
    },
  };
};
