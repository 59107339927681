import React, { useEffect } from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const CoursesForm = ({ create, update, course, idCourse, careerId }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: 0,
      start_date: "",
      discount: 0,
      discount_uses_tools: 0,
      schedule: "",
      quota: 10,
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    const formatedDate = new Date(
      data.start_date + "T00:00:00"
    ).toLocaleDateString();
    data.start_date = formatedDate;
    course === undefined
      ? create({ ...data, career_id: Number(careerId) })
      : update(data);
    navigate("/admin/all-courses");
  };

  const modifyDate = (date) => {
    if (date !== undefined) {
      const courseDate = date.split("/");
      let day = courseDate[0];
      let month = courseDate[1];
      let year = courseDate[2];

      return `${year}-${month}-${day}`;
    }
  };

  useEffect(() => {
    if (course !== undefined) {
      setValue("price", course.price);
      setValue("discount", course.discount);
      setValue("discount_uses_tools", course.discount_uses_tools);
      setValue("start_date", modifyDate(course.start_date));
      setValue("schedule", course.schedule);
      setValue("quota", course.current_quota);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  return (
    <div className="courses-form-main-container">
      <Container fluid className="courses-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmit)} className="courses-form">
          {/* price */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Precio
              </Form.Label>
              <Controller
                name="price"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.price}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.price?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
              {errors.price?.type === "min" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *El valor de este campo no puede ser menor a 1
                </Stack>
              )}
            </Form.Group>
          </Row>
          {/* discount */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Descuento por código
              </Form.Label>
              <Controller
                name="discount"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.discount}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.discount?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* discount_uses_tools */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Descuento por Herramientas
              </Form.Label>
              <Controller
                name="discount_uses_tools"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.discount_uses_tools}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.discount_uses_tools?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* start_date */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Fecha de inicio
              </Form.Label>
              <Controller
                name="start_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="Date"
                      isInvalid={errors.start_date}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.start_date?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* schedule */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Horario
              </Form.Label>
              <Controller
                name="schedule"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Horario de la comisión"
                      isInvalid={errors.schedule}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.schedule?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto courses-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* quota */}
          <Row className="courses-form-grid-row">
            <Form.Group
              as={Col}
              className="courses-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="courses-form-input-label">
                Cupos disponibles
              </Form.Label>
              <Controller
                name="quota"
                control={control}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.level}
                      className="courses-form-input"
                    />
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>

          <div className="courses-form-sumbit-btn-container">
            <button
              onClick={goBack}
              type="submit"
              className="courses-form-back-btn"
            >
              Volver
            </button>
            <button type="submit" className="courses-form-submit-btn">
              {course !== undefined ? "Editar" : "Crear"}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default CoursesForm;
