import "./Herramientas.scss";
import Maquinas from "../components/machines/Maquinas";
import LandingButtons from "../../landing/components/LandingButtons";

const Herramientas = () => {
  return (
      <div className="courseTools-container">
        <LandingButtons />
        <div className="herramientas-container">
          <h1>Estudiá con todo Wahl y Andis</h1>
          <p>
            Las herramientas no hacen al barbero, pero ayudan a mejorar el
            rendimiento
          </p>
          <h2>Todas las máquinas sin cable, para mayor comodidad</h2>
          <Maquinas />
        </div>
      </div>
  );
};

export default Herramientas;
