export const StudentUpdatePresenter = (
  getStudentGateway,
  updateStudentGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("StudentEdit was rendered!");
    },
    getStudentToUpd(id) {
      const getStudentToUpdPromise = getStudentGateway.getStudent(id);

      getStudentToUpdPromise
        .then((data) => {
          viewHandlers.getStudentToUpdSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getStudentToUpdError(err);
        });
    },
    updateStudent(data) {
      const updateStudentPromise = updateStudentGateway.updateStudent(data);

      updateStudentPromise
        .then((data) => {
          viewHandlers.updateStudentSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateStudentError(err);
        });
    },
  };
};
