import React, { useEffect } from "react";
import "./styles.scss";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";

const CareersForm = ({ create, update, career, idCareer }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      introduction: "",
      requirements: "",
      duration: 0,
      program_url: "",
      level: 0,
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    career === undefined
      ? create(data)
      : update({ ...data, id: Number(idCareer) });
    navigate("/admin/all-careers");
  };



  useEffect(() => {
    if (career !== undefined) {
      setValue("name", career.name);
      setValue("level", career.level);
      setValue("introduction", career.introduction);
      setValue("requirements", career.requirements);
      setValue("duration", career.duration);
      setValue("program_url", career.program_url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [career]);

  return (
    <div className="careers-form-main-container">
      <Container fluid className="careers-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmit)} className="careers-form">
          {/* Name */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Nombre
              </Form.Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Nombre de la carrera"
                      isInvalid={errors.name}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.name && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* duration */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Duración en meses
              </Form.Label>
              <Controller
                name="duration"
                control={control}
                rules={{ required: true, min: 1, max: 12 }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.duration}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.duration?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
              {(errors.duration?.type === "min" ||
                errors.duration?.type === "max") && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Debe ser un valor numérico entre 1 y 12
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* level */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Nivel
              </Form.Label>
              <Controller
                name="level"
                control={control}
                rules={{ required: true, min: 1, max: 10 }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.level}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.level?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
              {(errors.level?.type === "min" ||
                errors.level?.type === "max") && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Debe ser un valor numérico entre 1 y 10
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* introduction */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Introducción
              </Form.Label>
              <Controller
                name="introduction"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      as="textarea"
                      rows="3"
                      placeholder="Algunos datos acerca de la carrera..."
                      isInvalid={errors.introduction}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.introduction?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* requirements */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Requisitos
              </Form.Label>
              <Controller
                name="requirements"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Requisitos..."
                      isInvalid={errors.requirements}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.requirements?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* program_url */}
          <Row className="careers-form-grid-row">
            <Form.Group
              as={Col}
              className="careers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="careers-form-input-label">
                Programa
              </Form.Label>
              <Controller
                name="program_url"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Link del programa"
                      isInvalid={errors.program_url}
                      className="careers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.program_url && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto careers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          <div className="careers-form-buttons-container">
            <button onClick={goBack} className="careers-form-back-btn">
              Volver
            </button>
            <button type="submit" className="careers-form-submit-btn">
              {career !== undefined ? "Editar" : "Crear"}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default CareersForm;
