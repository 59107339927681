export const CareersDetailPresenter = (getCareerGateway, viewHandlers) => {
  return {
    present() {
      console.log("CareersDetail was rendered!");
    },
    getCareerById(id) {
      const getCareerPromise = getCareerGateway.getCareer(id);

      getCareerPromise
        .then((data) => {
          viewHandlers.getCareerSucces(data);
        })
        .catch((err) => {
          viewHandlers.getCareerError(err);
        });
    },
  };
};
