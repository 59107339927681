import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ActionButtons from "../../components/ActionButtons";
import DebtsDeleteModal from "../../components/DebtsDeleteModal";
import { getDebtsHomePresenter } from "../../infrastructure/presentation/PresenterProvider";
import "./styles.scss";

const DebtsHome = () => {
  const [debts, setDebts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [debtId, setDebtId] = useState(null);

  const viewHandlers = {
    getDebtsSuccess(data) {
      console.log("<<<<<<DEBTS>>>>>>>", data.statusText);
      setDebts(data.data.data);
    },
    getDebtsError(err) {
      console.log("<<<<<<DEBTS ERROR>>>>>>>", err);
    },
    deleteDebtSuccess(data) {
      console.log("<<<<<<DEBT DELETED>>>>>>>", data.statusText);
    },
    deleteDebtError(err) {
      console.log("<<<<<<DEBT DELETED ERROR>>>>>>>", err);
    },
  };

  const presenter = getDebtsHomePresenter(viewHandlers);

  const onDelete = (id) => {
    presenter.deleteDebt(id);
  };

  useEffect(() => {
    presenter.present();
    presenter.getDebts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="debts-home-main-container">
      <div className="debts-home-title-container">
        <div className="debts-home-title">Deudas</div>
      </div>
      <div className="debts-home-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="debts-home-table"
        >
          <thead className="debts-home-table-header">
            <tr className="debts-home-table-row">
              <th className="debts-home-column-title">ID</th>
              <th className="debts-home-column-title">ID Alumno</th>
              <th className="debts-home-column-title">Monto</th>
              <th className="debts-home-column-title">Estado</th>
              <th className="debts-home-column-title">Action buttons</th>
            </tr>
          </thead>
          <tbody className="debts-home-table-body">
            {debts.map((item) => {
              return (
                <tr className="debts-home-table-row" key={item.id}>
                  <td className="debts-home-column-data">{item.id}</td>
                  <td className="debts-home-column-data">{item.student_id}</td>
                  <td className="debts-home-column-data">{`$ ${item.amount}`}</td>
                  <td className="debts-home-column-data">{item.status}</td>
                  <td className="debts-home-column-data">
                    <ActionButtons
                      id={item.id}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      setDebt={setDebtId}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <DebtsDeleteModal
          showModal={showModal}
          setShowModal={setShowModal}
          deleteDebt={onDelete}
          debt={debtId}
        />
      )}
    </div>
  );
};

export default DebtsHome;
