import React, { useEffect, useState } from "react";
import LoginHeader from "../../components/LoginHeader";
import LoginForm from "../../components/LoginForm/index";
import "./styles.scss";
import useUser from "../../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { getStudentLoginPresenter } from "../../infrastructure/presentation/presenterProvider";

const StudentsLogin = () => {
  const { login, isLoading, isLogged, hasLoginError } = useUser();
  const [loginData, setLoginData] = useState(null);
  const navigate = useNavigate();

  const viewHandlers = {
    loginSuccess(data) {
      console.log("<<<<<<LOGIN>>>>>>>", data.data.message);
      login(data.data);
      setLoginData(data.data.data);
    },
    loginError(err) {
      console.log("<<<<<<LOGIN ERROR>>>>>>>", err);
      login(err);
    },
  };

  const presenter = getStudentLoginPresenter(viewHandlers);

  const handleLogin = (data) => {
    presenter.logIn(data);
  };

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* Esto debería llevar a la home del apartado de estudiantes */
    if (isLogged) {
      if (loginData && loginData.first_login) {
        navigate("/onboarding");
      } else {
        navigate("/student-profile");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, navigate]);

  return (
    <div className="students-login-main-container">
      <LoginHeader />
      <hr className="login-divider" />
      <div className="students-login-form-container">
        <div className="students-login-title">Login de alumnos</div>
        <LoginForm
          handleLogin={handleLogin}
          loading={isLoading}
          hasLoginError={hasLoginError}
        />
      </div>
      <hr className="login-divider" />
    </div>
  );
};

export default StudentsLogin;
