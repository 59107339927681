import axios from "axios";

export const HttpGetAllStudentsGateway = () => {
  return {
    getAllStudents: async () => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/business/student/get-all`
      );
    },
  };
};
