import React, { useEffect, useState } from "react";
import "./styles.scss";
import DebtsForm from "../../components/DebtsForm";
import { useParams } from "react-router-dom";
import { getDebtsUpdatePresenter } from "../../infrastructure/presentation/PresenterProvider";

const DebtsUpdate = () => {
  const { id } = useParams();
  const [debt, setDebt] = useState({});
  const [student, setStudent] = useState({});

  const viewHandlers = {
    getDebtSuccess(data) {
      console.log("<<<<<<DEBT>>>>>>>", data.statusText);
      setDebt(data.data.data);
      setStudent(data.data.data.student);
    },
    getDebtError(err) {
      console.log("<<<<<<DEBT ERROR>>>>>>>", err);
    },
    updateDebtSuccess(data) {
      console.log("<<<<<<DEBT UPDATED>>>>>>>", data.statusText);
    },
    updateDebtError(err) {
      console.log("<<<<<<DEBT UPD ERROR>>>>>>>", err);
    },
  };

  const presenter = getDebtsUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getDebt(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateDebt = (data) => {
    /* console.log("DATA DESPUËS DEL SUBMIT EN UPDATE: ", data); */
    presenter.updateDebt(data);
  };

  return (
    <div className="debts-update-main-container">
      <div className="debts-update-title-container">
        <div className="debts-update-title">{`Editar deuda de: ${student.name} ${student.lastname}`}</div>
      </div>
      <hr className="divider" />
      <div className="debts-update-form-container">
        <DebtsForm debt={debt} update={onUpdateDebt} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default DebtsUpdate;
