import axios from "axios";

export const HttpCreateStudentGateway = () => {
  return {
    createStudent: async (data) => {
      // console.log(data);
      const { name, lastname, document_number, age, email, password } = data;
      const body = {
        name,
        lastname,
        document_number ,
        age: Number(age),
        email,
        password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/student/create`,
        body
      );
    },
  };
};
