import { HttpGetStudentDataGateway } from "../gateways/HttpGetStudentDataGateway";
import { HttpCreateDebtGateway } from "../gateways/HttpCreateDebtGateway";
import { HttpGetAllDebtsGateway } from "../gateways/HttpGetAllDebtsGateway";
import { HttpDeleteDebtGateway } from "../gateways/HttpDeleteDebtGateway";
import { HttpGetDebtGateway } from "../gateways/HttpGetDebtGateway";
import { HttpUpdateDebtGateway } from "../gateways/HttpUpdateDebtGateway";

import { DebtsCreatePresenter } from "./DebtsCreatePresenter";
import { DebtsHomePresenter } from "./DebtsHomePresenter";
import { DebtsDetailPresenter } from "./DebtsDetailPresenter";
import { DebtsUpdatePresenter } from "./DebtsUpdatePresenter";

export const getDebtsCreatePresenter = (viewHandlers) => {
  const getStudentDataGateway = HttpGetStudentDataGateway();
  const getCreateDebtGateway = HttpCreateDebtGateway();
  return DebtsCreatePresenter(
    getStudentDataGateway,
    getCreateDebtGateway,
    viewHandlers
  );
};

export const getDebtsHomePresenter = (viewHandlers) => {
  const getAllDebtsGateway = HttpGetAllDebtsGateway();
  const getDeleteDebtsGateway = HttpDeleteDebtGateway();
  return DebtsHomePresenter(
    getAllDebtsGateway,
    getDeleteDebtsGateway,
    viewHandlers
  );
};

export const getDebtsDetailPresenter = (viewHandlers) => {
  const getDebtGateway = HttpGetDebtGateway();
  return DebtsDetailPresenter(getDebtGateway, viewHandlers);
};

export const getDebtsUpdatePresenter = (viewHandlers) => {
  const getDebtGateway = HttpGetDebtGateway();
  const getUpdateDebtGateway = HttpUpdateDebtGateway();
  return DebtsUpdatePresenter(
    getDebtGateway,
    getUpdateDebtGateway,
    viewHandlers
  );
};
