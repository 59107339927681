import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SalesForm from "../../components/SalesForm";
import { getCreateSalePresenter } from "../../infrastructure/presentation/presenterProvider";
import { CartContext } from "../../../../context/CartContext";
import "./styles.scss";

const CreateSale = () => {
  const [item, setItem] = useState(null);
  const [career, setCareer] = useState(null);
  const { product, clearCart } = useContext(CartContext);
  const navigate = useNavigate();

  const viewHandlers = {
    createSaleSuccess(data) {
      console.log("<<<<<<CREATE SALE>>>>>>>", data.data.message);
      const navigateInitPoint = document.createElement("a");
      navigateInitPoint.href = data.data.data.init_point;
      navigateInitPoint.target = "_blank";
      navigateInitPoint.click();
      setTimeout(() => {
        navigate("/");
      }, 1000);
    },
    createSaleError(err) {
      console.log("<<<<<<CREATE SALE ERROR>>>>>>>", err);
    },
  };

  const presenter = getCreateSalePresenter(viewHandlers);

  const createNewSale = (data) => {
    presenter.createSale(data);
  };

  useEffect(() => {
    presenter.present();
    setItem(product);
    setCareer(product.career);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-sale-main-container">
      <div className="create-sale-title-container">
        <div className="create-sale-title">Proceso de compra</div>
      </div>
      {item ? (
        <>
          <Container fluid className="create-sale-data-container">
            <Row className="create-sale-grid-row">
              <Col
                className="create-sale-grid-item"
                xs={12}
                sm={12}
                md={7}
                lg={7}
              >
                <Container fluid className="create-sale-grid-item-data">
                  <Row className="create-sale-inner-grid-row">
                    <h2 className="item-name">{`${career.name}`}</h2>
                  </Row>
                  <Row className="create-sale-inner-grid-row">
                    <Col
                      className="create-sale-inner-col"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <div className="item-data-card">
                        <div className="item-start-date">Fecha de inicio</div>
                        <div className="item-data">{`${item.start_date}`}</div>
                      </div>
                    </Col>
                    <Col
                      className="create-sale-inner-col"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <div className="item-data-card">
                        <div className="item-duration">Duración</div>
                        <div className="item-data">{`${career.duration} meses`}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="create-sale-inner-grid-row">
                    <Col className="create-sale-inner-col">
                      <div className="item-data-card">
                        <div className="item-requirements">Requisitos</div>
                        <div className="item-data">{`${career.requirements}`}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="create-sale-inner-grid-row">
                    <Col className="create-sale-inner-col">
                      <div className="item-data-card">
                        <div className="item-introduction">Introducción</div>
                        <div className="item-data">{`${career.introduction}`}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="create-sale-inner-grid-row">
                    <Col className="create-sale-inner-col">
                      <div className="item-data-card-price">
                        <h3 className="item-price">
                          Precio sin descuento: {`ARS$ ${item.price}`}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col
                className="create-sale-grid-item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
              >
                <SalesForm createSale={createNewSale} clearCart={clearCart} />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>no data</>
      )}
    </div>
  );
};

export default CreateSale;
