import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import "./styles.scss";
import ActionButtons from "../../components/ActionButtons";
import { useNavigate } from "react-router-dom";
import {
  getSellerDeletePresenter,
  getSellersHomePresenter,
} from "./../../infrastructure/presentation/presenterProvider";
import SellerDelete from "./../../components/SellerDeleteModal/index";

const SellersHome = () => {
  const [sellers, setSellers] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [sellerClicked, setSellerClicked] = useState(null);

  const viewHandlers = {
    getSellersSucces(data) {
      console.log("<<<<SELLERS>>>>", data.statusText);
      setSellers(data.data.data);
    },
    getSellersError(err) {
      console.log("<<<<Home ERROR>>>>", err);
    },
  };

  const deleteViewHandlers = {
    deleteSellerSuccess(data) {
      console.log("<<<<<<VENDEDOR ELIMINADO>>>>>>>", data.statusText);
    },
    deleteSellerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellersHomePresenter(viewHandlers);

  const deletePresenter = getSellerDeletePresenter(deleteViewHandlers);

  const onDelete = (id) => {
    deletePresenter.deleteSeller(id);
  };

  useEffect(() => {
    presenter.present();
    presenter.getSellers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    deletePresenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  return (
    <div className="sellers-home-main-container">
      <div className="seller-home-title">Vendedores</div>
      <div className="sellers-home-add-button-container">
        <Button
          variant="outline-light"
          onClick={() => navigate("/admin/register-seller")}
        >
          Registrar Vendedor
        </Button>
      </div>
      <div className="sellers-home-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="sellers-home-table"
        >
          <thead className="sellers-home-table-header">
            <tr className="sellers-home-table-row">
              <th className="sellers-home-column-title">Id</th>
              <th className="sellers-home-column-title">Nombre</th>
              <th className="sellers-home-column-title">Apellido</th>
              <th className="sellers-home-column-title">DNI</th>
              <th className="sellers-home-column-title">Email</th>
              <th className="sellers-home-column-title">Código Ref.</th>
              <th className="sellers-home-column-title">Acciones</th>
            </tr>
          </thead>
          <tbody className="sellers-home-table-body">
            {sellers.map((seller) => {
              return (
                <tr className="sellers-home-row" key={seller.id}>
                  <td className="sellers-home-column-data">{seller.id}</td>
                  <td className="sellers-home-column-data">{seller.name}</td>
                  <td className="sellers-home-column-data">
                    {seller.lastname}
                  </td>
                  <td className="sellers-home-column-data">
                    {seller.document_number}
                  </td>
                  <td className="sellers-home-column-data">{seller.email}</td>
                  <td className="sellers-home-column-data">
                    {seller.referral_code}
                  </td>
                  <td className="sellers-home-column-data">
                    <ActionButtons
                      id={seller.id}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      setSeller={setSellerClicked}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <SellerDelete
          showModal={showModal}
          setShowModal={setShowModal}
          deleteSeller={onDelete}
          seller={sellerClicked}
        />
      )}
    </div>
  );
};
export default SellersHome;
