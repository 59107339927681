import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./styles.scss";
import ActionButtons from "../../components/ActionButtons";
import { Link } from "react-router-dom";
import {
  getCourseDeletePresenter,
  getCoursesHomePresenter,
} from "../../infrastructure/presentation/presenterProvider";
import CourseDelete from "../../components/CourseDeleteModal";

const CoursesHome = () => {
  const [courses, setCourses] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [courseClicked, setCourseClicked] = useState(null);

  const viewHandlers = {
    getCoursesSuccess(data) {
      console.log("<<<<<<CURSOS>>>>>>>", data.statusText);
      setCourses(data.data.data);
    },
    getCoursesError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const deleteViewHandlers = {
    deleteCourseSuccess(data) {
      console.log("<<<<<<CURSO ELIMINADO>>>>>>>", data.statusText);
    },
    deleteCourseError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getCoursesHomePresenter(viewHandlers);

  const deletePresenter = getCourseDeletePresenter(deleteViewHandlers);

  const onDelete = (id) => {
    deletePresenter.deleteCourse(id);
  };

  useEffect(() => {
    presenter.present();
    presenter.getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    deletePresenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="courses-home-main-container">
      <div className="courses-home-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="courses-home-table"
        >
          <thead className="courses-home-table-header">
            <tr className="courses-home-table-row">
              <th className="courses-home-column-title">Id</th>
              <th className="courses-home-column-title">Nombre</th>
              <th className="courses-home-column-title">Fecha Inicio</th>
              <th className="courses-home-column-title">Precio</th>
              <th className="courses-home-column-title">Descuento</th>
              <th className="courses-home-column-title">Acciones</th>
            </tr>
          </thead>
          <tbody className="courses-home-table-body">
            {courses.map((course) => {
              return (
                <tr className="courses-home-row" key={course.id}>
                  <td className="courses-home-column-data">{course.id}</td>
                  <td className="courses-home-column-data">
                    <Link
                      to={`/admin/detail-career/${course.career.id}`}
                      style={{ color: "white" }}
                    >
                      {course.career.name}
                    </Link>
                  </td>
                  <td className="courses-home-column-data">
                    {course.start_date}
                  </td>
                  <td className="courses-home-column-data">{`$ ${course.price}`}</td>
                  <td className="courses-home-column-data">
                    {`${course.discount} %`}
                  </td>
                  <td className="courses-home-column-data">
                    <ActionButtons
                      id={course.id}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      setCourse={setCourseClicked}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <CourseDelete
          showModal={showModal}
          setShowModal={setShowModal}
          deleteCourse={onDelete}
          course={courseClicked}
        />
      )}
    </div>
  );
};

export default CoursesHome;
