export const CareersUpdatePresenter = (
  getCareerGateway,
  updateCareerGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("CareersEdit was rendered!");
    },
    getCareerToUpdate(id) {
      const getCareerToUpdatePromise = getCareerGateway.getCareer(id);

      getCareerToUpdatePromise
        .then((data) => {
          viewHandlers.getCareerToUpdateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCareerToUpdateError(err);
        });
    },
    executeCareerUpdate(data) {
      const executeUpdatePromise = updateCareerGateway.updateCareer(data);

      executeUpdatePromise
        .then((data) => {
          viewHandlers.executeCareerUpdateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.executeCareerUpdateError(err);
        });
    },
  };
};
