import axios from "axios";

export const HttpStudentUpdateGateway = () => {
  return {
    updateStudent: async (data) => {
      const { id, name, lastname, age, password, status, first_login } = data;
      const body = {
        id: Number(id),
        name,
        lastname,
        age: Number(age),
        password,
        status: Number(status),
        first_login,
      };
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/student/update`,
        body
      );
    },
  };
};
