import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import DebtsForm from "../../components/DebtsForm";
import { getDebtsCreatePresenter } from "../../infrastructure/presentation/PresenterProvider";

const DebtsCreate = () => {
  const { id } = useParams();
  const [inscriptions, setInscriptions] = useState([]);

  const viewHandlers = {
    getStudentInfoSuccess(data) {
      console.log("<<<<<<STUDENT DATA>>>>>>>", data.statusText);
      setInscriptions(data.data.data);
    },
    getStudentInfoError(err) {
      console.log("<<<<<<STUDENT ERROR>>>>>>>", err);
    },
    createDebtSuccess(data) {
      console.log("<<<<<<DEBT DATA>>>>>>>", data.statusText);
    },
    createDebtError(err) {
      console.log("<<<<<<DEBT ERROR>>>>>>>", err);
    },
  };

  const presenter = getDebtsCreatePresenter(viewHandlers);

  const createNewDebt = (data) => {
    presenter.createDebt(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getStudentInfo(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO falta recibir toda la data del formulario y comunicar con el back, después solo quedaría actualizar una deuda y eliminar una deuda, todo en el componente home

  return (
    <div className="debts-create-main-container">
      <div className="debts-create-title-container">
        <div className="debts-create-title">{`Crear deuda para estudiante ${id}`}</div>
      </div>
      <hr className="divider" />
      <div className="debts-from-container">
        <DebtsForm inscriptions={inscriptions} create={createNewDebt} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default DebtsCreate;
