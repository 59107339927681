import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Table, Button } from "react-bootstrap";
import {
  getCareersDeletePresenter,
  getCareersHomePresenter,
} from "../../infrastructure/presentation/presenterProvider";
import ActionButtons from "../../components/ActionButtons";
import CareersDelete from "../../components/CareersDeleteModal";
import { useNavigate } from "react-router-dom";

const CareersHome = () => {
  const [careers, setCareers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [careerId, setCareerId] = useState(null);
  const navigate = useNavigate();

  const viewHandlers = {
    getAllCareersSuccess(data) {
      console.log("<<<<<<CARRERAS>>>>>>>", data.statusText);
      setCareers(data.data.data);
    },
    getAllCareersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const deleteViewHandlers = {
    deleteCareerSuccess(data) {
      console.log("<<<<<<CARRERA ELIMINADA>>>>>>>", data.statusText);
    },
    deleteCareerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getCareersHomePresenter(viewHandlers);

  const deletePresenter = getCareersDeletePresenter(deleteViewHandlers);

  const onDelete = (id) => {
    deletePresenter.deleteCareer(id);
  };

  useEffect(() => {
    presenter.present();
    presenter.getCarrers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    deletePresenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="careers-home-main-container">
      <div className="careers-home-add-button-container">
        <Button
          variant="outline-light"
          onClick={() => navigate("/admin/create-career")}
        >
          Agregar Carrera
        </Button>
      </div>
      <div className="careers-home-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="careers-home-table"
        >
          <thead className="careers-home-table-header">
            <tr className="careers-home-table-row">
              <th className="careers-home-column-title">id</th>
              <th className="careers-home-column-title">Nombre</th>
              <th className="careers-home-column-title">Duración</th>
              <th className="careers-home-column-title">Nivel</th>
              <th className="careers-home-column-title">Tiene programa</th>
              <th className="careers-home-column-title">Acciones</th>
            </tr>
          </thead>
          <tbody className="careers-home-table-body">
            {careers.map((career) => {
              return (
                <tr className="careers-home-table-row" key={career.id}>
                  <td className="careers-home-column-data">{career.id}</td>
                  <td className="careers-home-column-data">{career.name}</td>
                  <td className="careers-home-column-data">
                    {`${career.duration} meses`}
                  </td>
                  <td className="careers-home-column-data">{career.level}</td>
                  <td className="careers-home-column-data">
                    {career.program_url ? "Si" : "No"}
                  </td>
                  <td className="careers-home-column-data">
                    <ActionButtons
                      id={career.id}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      setCareer={setCareerId}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <CareersDelete
          showModal={showModal}
          setShowModal={setShowModal}
          deleteCareer={onDelete}
          career={careerId}
        />
      )}
    </div>
  );
};

export default CareersHome;
