import React from "react";
import "leaflet/dist/leaflet.css";
import "./styles.scss";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const Map = () => {
  const taxibarberIcon = L.icon({
    iconUrl: require("../../../../../assets/icons/tb-enseña.png"),
    iconSize: 50,
    className: "market-icon",
  });
  const control700Icon = L.icon({
    iconUrl: require("../../../../../assets/icons/control-700.png"),
    iconSize: 50,
    className: "market-icon",
  });

  const address = [-33.031630360024465, -68.90270922942727];
  const position = [-33.032053740728635, -68.90254019672078];

  return (
    <div className="address-map-container">
      <MapContainer center={address} zoom={17} scrollWheelZoom={false} id="map">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={address} icon={taxibarberIcon}>
          <Popup>
            Taxibarber Enseña <br /> Embalse Potrerillos 350, <br /> M5509
            Vistalba, Mendoza.
          </Popup>
        </Marker>
        <Marker position={position} icon={control700Icon}>
          <Popup>Control 700</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
