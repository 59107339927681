export const CareersHomePresenter = (getAllCareersGateway, viewHandlers) => {
  return {
    present() {
      console.log("CarrersHome was rendered!");
    },
    getCarrers() {
      const getCarrersPromise = getAllCareersGateway.getAllCareers();

      getCarrersPromise
        .then((data) => {
          viewHandlers.getAllCareersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllCareersError(err);
        });
    },
  };
};
