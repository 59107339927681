import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import diploma from "../../../../assets/diploma.jpg";
import carnet from "../../../../assets/CARNET.jpg";
import useUser from "../../../../hooks/useUser";
import DiplomaPDF from "../../components/Diploma/DiplomaPDF";
import CarnetPDF from "../../components/Carnet/CarnetPDF";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import "./styles.scss";
import { PDFDownloadLink } from "@react-pdf/renderer";

const ProfileDetail = () => {
  const { user } = useUser();
  const { state } = useLocation();
  const [inscription, setInscription] = useState(state.inscription);
  const [careersList, setCareersList] = useState(state.list);
  const [course, setCourse] = useState(state.inscription.course);
  const [career, setCareer] = useState(state.inscription.course.career);
  const [nowState, setNowState] = useState(0);

  const programPreview = (program) => {
    const preview = document.createElement("a");
    preview.href = program;
    preview.target = "_blank";
    preview.click();
  };

  const toDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    setInscription(state.inscription);
    setCourse(inscription.course);
    setCareer(inscription.course.career);
    setCareersList(state.list);
    if (course) {
      const initialDate = toDate(course.start_date).getTime() / 1000;
      const monthsDuration = career.duration * 2629743;
      const finalDate = initialDate + monthsDuration;
      const today = new Date().getTime() / 1000;
      const totalDuration = initialDate - finalDate;
      const timeElapsed = initialDate - today;
      setNowState((Math.round(timeElapsed * 100) / totalDuration).toFixed(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="spd-main-container">
      <div className="spd-title-container">
        <div className="spd-title">{career.name}</div>
      </div>

      <section className="spd-section">
        <div className="spd-progress-container">
          <div className="spd-progress">
            <div className="spd-career-data-title">Mi progreso</div>
            <div className="spd-progress-bar-container">
              <ProgressBar
                className="progress-bar"
                striped
                animated
                variant="success"
                now={nowState}
                label={nowState >= 100 ? "100%" : `${nowState}%`}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="spd-section">
        <div className="spd-career-data-title">Información general</div>
        <Container fluid className="spd-grid-container">
          <Row className="spd-grid-row">
            <Col className="spd-grid-col" sm={12} md={5} lg={5}>
              <div className="spd-grid-title">Duración: </div>
              <div className="spd-grid-data">{`${career.duration} meses`}</div>
            </Col>
            <Col className="spd-grid-col" sm={12} md={5} lg={5}>
              <div className="spd-grid-title">Nivel: </div>
              <div className="spd-grid-data">{`${career.level}`}</div>
            </Col>
          </Row>
          <Row className="spd-grid-row">
            <Col className="spd-grid-col" sm={12} md={5} lg={5}>
              <div className="spd-grid-title">Fecha de inicio: </div>
              <div className="spd-grid-data">{`${course.start_date}`}</div>
            </Col>
            <Col className="spd-grid-col" sm={12} md={5} lg={5}>
              <button
                className="spd-grid-button"
                onClick={() => programPreview(career.program_url)}
              >
                Ver programa
              </button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="spd-section">
        <div className="spd-career-data-title">Mis certificados</div>
        <div className="spd-certificates-title">
          {nowState < 100 ? (
            <h3>
              Una vez finalizado tu proceso de formación, podrás descargar tus
              certificados
            </h3>
          ) : (
            <h3>Felicidades, ya están disponibles tus certificados</h3>
          )}
        </div>
        <Container fluid className="spd-grid-container">
          {nowState < 100 ? (
            <Row className="spd-grid-row">
              <Col className="spd-grid-col-centered" sm={12} md={5} lg={5}>
                <img src={diploma} alt="diploma-example" width={150} />
              </Col>
              <Col className="spd-grid-col-centered" sm={12} md={5} lg={5}>
                <img src={carnet} alt="carnet-example" width={200} />
              </Col>
            </Row>
          ) : (
            <Row className="spd-grid-row">
              <Col className="spd-grid-col-centered" sm={12} md={5} lg={5}>
                <PDFDownloadLink
                  document={
                    <DiplomaPDF
                      name={user.name}
                      dni={state.inscription.studentDNI}
                      lastname={user.lastname}
                      career={career.name}
                    />
                  }
                  fileName={`Diploma ${user.name}.pdf`}
                >
                  <button className="spd-download-button">
                    Descargar mi diploma
                  </button>
                </PDFDownloadLink>
              </Col>
              <Col className="spd-grid-col-centered" sm={12} md={5} lg={5}>
                <PDFDownloadLink
                  document={
                    <CarnetPDF
                      name={user.name}
                      lastname={user.lastname}
                      id={user.id}
                      list={careersList}
                    />
                  }
                  fileName={`Carnet ${user.name}-${user.lastname}.pdf`}
                >
                  <button className="spd-download-button">
                    Descargar mi carnet
                  </button>
                </PDFDownloadLink>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </div>
  );
};

export default ProfileDetail;
