import React, { useEffect } from "react";
import "./styles.scss";
import CoursesForm from "../../components/CoursesForm";
import { getCourseCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import { useParams } from "react-router-dom";

const CourseCreate = () => {
  const { id } = useParams();

  const viewHandlers = {
    createCourseSuccess(data) {
      console.log("<<<<<<COURSE CREATE>>>>>>>", data.data.message);
    },
    createCourseError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getCourseCreatePresenter(viewHandlers);

  const createNewCourse = (data) => {
    presenter.createCourse(data);
  };

  useEffect(() => {
    presenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="course-create-main-container">
      <div className="course-create-title-container">
        <div className="course-create-title">Nueva Comisión</div>
      </div>
      <hr className="divider" />
      <div className="course-form-container">
        <CoursesForm create={createNewCourse} careerId={id} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default CourseCreate;
