import axios from "axios";

export const HttpGetCareerGateway = () => {
  return {
    getCareerById: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/business/career/get/id/${id}`
      );
    },
  };
};
