import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SellerForm = ({ create, update, seller, idSeller }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const handleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show);
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      lastname: "",
      documentNumber: 0,
      email: "",
      password: "",
      referral_code: "",
      username: "",
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    seller === undefined
      ? create(data)
      : update({ ...data, id: Number(idSeller) });
    navigate("/admin/all-sellers");
  };

  useEffect(() => {
    if (seller !== undefined) {
      setValue("name", seller.name);
      setValue("lastname", seller.lastname);
      setValue("documentNumber", seller.documentNumber);
      setValue("email", seller.email);
      setValue("password", seller.password);
      setValue("referral_code", seller.referral_code);
      setValue("username", seller.username);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  return (
    <div className="sellers-form-main-container">
      <Container fluid className="sellers-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmit)} className="sellers-form">
          {/* NOMBRE */}
          <Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Nombre
              </Form.Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="nombre"
                      isInvalid={errors.name}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.name && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto sellers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          <Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Apellido
              </Form.Label>
              <Controller
                name="lastname"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="apellido"
                      isInvalid={errors.lastname}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.lastname && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto sellers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          <Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">DNI</Form.Label>
              <Controller
                name="documentNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.documentNumber}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.documentNumber?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto sellers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>
          {/* <Form.Group className="formBirthdate">
            <Form.Label className="input-label">Fecha de Nacimiento</Form.Label>
            <DatePicker
              className="datePicker-container"
              selected={birthdate}
              onChange={(date) => setBirthdate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group> */}
          {seller !== undefined && (<Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Nombre de Usuario
              </Form.Label>
              <Controller
                name="username"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={errors.username}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>)}

          <Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Email
              </Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="email"
                      placeholder="abc@abc.com"
                      isInvalid={errors.email}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.email && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto sellers-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          <Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Contraseña
              </Form.Label>
              <Controller
                name="password"
                control={control}
                rules={{ required: false, minLength: 8 }}
                render={({ field }) => (
                  <Stack
                    direction="horizontal"
                    className="col-md-6 mx-auto"
                    gap={1}
                  >
                    <Form.Control
                      {...field}
                      type={inputType}
                      placeholder="**********"
                      isInvalid={errors.password}
                      className="sellers-form-input"
                    />
                    <button
                      onClick={handleShowPassword}
                      variant="outline-secondary"
                      id="button-addon1"
                      className="psw-button"
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          color="#FFFF"
                          size="2x"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEye} color="#FFFF" size="2x" />
                      )}
                    </button>
                  </Stack>
                )}
              />
              {errors.password?.type === "minLength" && (
                <Stack
                  direction="horizontal"
                  className="col-md-5 mx-auto login-form-input-error"
                >
                  La contraseña debe tener al menos 8 caracteres
                </Stack>
              )}
            </Form.Group>
          </Row>
          {seller !== undefined && (<Row className="sellers-form-grid-row">
            <Form.Group
              as={Col}
              className="sellers-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sellers-form-input-label">
                Código de Referido
              </Form.Label>
              <Controller
                name="referral_code"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={errors.referral_code}
                      className="sellers-form-input"
                    />
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>)}
          <div className="sellers-form-sumbit-btn-container">
            <button
              onClick={goBack}
              type="submit"
              className="sellers-form-back-btn"
            >
              Volver
            </button>
            <button type="submit" className="sellers-form-submit-btn">
              {seller !== undefined ? "Editar" : "Crear"}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default SellerForm;
