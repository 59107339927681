import axios from "axios";

export const HttpDeleteCareerGateway = () => {
  return {
    deleteCareer: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/business/career/delete/id/${id}`
      );
    },
  };
};
