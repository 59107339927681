export const SellersHomePresenter = (getAllSellersGateway, viewHandlers) => {
    return {
      present() {
        console.log("AllSellers was rendered!");
      },
      getSellers() {
        const getAllSellersPromise = getAllSellersGateway.getAllSellers();
  
        getAllSellersPromise
          .then((data) => {
            viewHandlers.getSellersSucces(data);
          })
          .catch((err) => {
            viewHandlers.getSellersError(err);
          });
      },
    };
  };
  