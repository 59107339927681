export const CourseDeletePresenter = (courseDeleteGateway, viewHandlers) => {
  return {
    present() {
      console.log("CourseDelete was rendered!");
    },
    deleteCourse(id) {
      const deleteCoursePromise = courseDeleteGateway.deleteCourse(id);

      deleteCoursePromise
        .then((data) => {
          viewHandlers.deleteCourseSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteCourseError(err);
        });
    },
  };
};
