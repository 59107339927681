import PrivateRoute from "../../utils/navigation";
import { Roles } from "../roles";

/* Components-Admin-Courses */
import CoursesHome from "../../modules/Admin/Courses/views/CoursesHome";
import CourseCreate from "../../modules/Admin/Courses/views/CoursesCreate";
import DetailCourse from "../../modules/Admin/Courses/views/CoursesDetail";
import CourseEdit from "../../modules/Admin/Courses/views/CoursesEdit";
/* Components-Admin-Careers */
import CareersHome from "../../modules/Admin/Careers/views/CareersHome";
import CareersCreate from "../../modules/Admin/Careers/views/CareersCreate";
import CareersDetail from "../../modules/Admin/Careers/views/CareersDetail";
import CareersEdit from "../../modules/Admin/Careers/views/CareersEdit";
/* Components-Admin-Sellers */
import SellersHome from "../../modules/Admin/Sellers/views/SellersHome";
import SellerCreate from "../../modules/Admin/Sellers/views/SellersCreate";
import SellerDetail from "../../modules/Admin/Sellers/views/SellersDetail";
import SellerEdit from "../../modules/Admin/Sellers/views/SellersEdit";
/* Components-Admin-Students */
import StudentsHome from "../../modules/Admin/Students/views/StudentsHome";
import StudentCreate from "../../modules/Admin/Students/views/StudentsCreate";
import StudentDetail from "../../modules/Admin/Students/views/StudentsDetail";
import StudentEdit from "../../modules/Admin/Students/views/StudentsEdit";
/* Components-Admin-Debts */
import DebtsHome from "../../modules/Admin/Debts/views/DebtsHome";
import DebtsCreate from "../../modules/Admin/Debts/views/DebtsCreate";
import DebtsUpdate from "../../modules/Admin/Debts/views/DebtsUpdate";
import DebtsDetail from "../../modules/Admin/Debts/views/DebtsDetail";

/* Components Sellers */
import SellerProfile from "../../modules/Sellers/views/Profile";

/* Components Students */
import StudentProfile from "../../modules/Students/views/Profile";
import ProfileDetail from "../../modules/Students/views/ProfileDetail";

/* Admin courses routes */

export const COURSES_HOME = {
  path: "/admin/all-courses",
  element: (
    <PrivateRoute
      path="/admin/all-courses"
      roles={[Roles.Admin]}
      Component={CoursesHome}
    />
  ),
};

export const COURSES_CREATE = {
  path: "/admin/create-course/:id",
  element: (
    <PrivateRoute
      path="/admin/create-course/:id"
      roles={[Roles.Admin]}
      Component={CourseCreate}
    />
  ),
};

export const COURSES_DETAIL = {
  path: "/admin/detail-course/:id",
  element: (
    <PrivateRoute
      path="/admin/detail-course/:id"
      roles={Roles.Admin}
      Component={DetailCourse}
    />
  ),
};

export const COURSES_UPDATE = {
  path: "/admin/update-course/:id",
  element: (
    <PrivateRoute
      path="/admin/update-course/:id"
      roles={[Roles.Admin]}
      Component={CourseEdit}
    />
  ),
};

/* Admin careers routes */

export const CAREERS_HOME = {
  path: "/admin/all-careers",
  element: (
    <PrivateRoute
      path="/admin/all-careers"
      roles={[Roles.Admin]}
      Component={CareersHome}
    />
  ),
};

export const CAREERS_CREATE = {
  path: "/admin/create-career",
  element: (
    <PrivateRoute
      path="/admin/create-career"
      roles={[Roles.Admin]}
      Component={CareersCreate}
    />
  ),
};

export const CAREERS_DETAIL = {
  path: "/admin/detail-career/:id",
  element: (
    <PrivateRoute
      path="/admin/detail-career/:id"
      roles={[Roles.Admin]}
      Component={CareersDetail}
    />
  ),
};

export const CAREERS_UPDATE = {
  path: "/admin/update-career/:id",
  element: (
    <PrivateRoute
      path="/admin/update-career/:id"
      roles={[Roles.Admin]}
      Component={CareersEdit}
    />
  ),
};

/* Admin-sellers-routes */

export const SELLERS_HOME = {
  path: "/admin/all-sellers",
  element: (
    <PrivateRoute
      path="/admin/all-sellers"
      roles={[Roles.Admin]}
      Component={SellersHome}
    />
  ),
};

export const SELLERS_CREATE = {
  path: "/admin/register-seller",
  element: (
    <PrivateRoute
      path="/admin/register-seller"
      roles={[Roles.Admin]}
      Component={SellerCreate}
    />
  ),
};

export const SELLERS_DETAIL = {
  path: "/admin/detail-seller/:id",
  element: (
    <PrivateRoute
      path="/admin/detail-seller/:id"
      roles={[Roles.Admin]}
      Component={SellerDetail}
    />
  ),
};

export const SELLERS_UPDATE = {
  path: "/admin/update-seller/:id",
  element: (
    <PrivateRoute
      path="/admin/update-seller/:id"
      roles={[Roles.Admin]}
      Component={SellerEdit}
    />
  ),
};

/* Admin-Students-routes */

export const STUDENTS_HOME = {
  path: "/admin/all-students",
  element: (
    <PrivateRoute
      path="/admin/all-students"
      roles={[Roles.Admin]}
      Component={StudentsHome}
    />
  ),
};

export const STUDENTS_CREATE = {
  path: "/admin/register-student",
  element: (
    <PrivateRoute
      path="/admin/register-student"
      roles={[Roles.Admin]}
      Component={StudentCreate}
    />
  ),
};

export const STUDENTS_DETAIL = {
  path: "/admin/detail-student/:id",
  element: (
    <PrivateRoute
      path="/admin/detail-student/:id"
      roles={[Roles.Admin]}
      Component={StudentDetail}
    />
  ),
};

export const STUDENTS_UPDATE = {
  path: "/admin/update-student/:id",
  element: (
    <PrivateRoute
      path="/admin/update-student/:id"
      roles={[Roles.Admin]}
      Component={StudentEdit}
    />
  ),
};

/* Admin debts routes */

export const DEBTS_HOME = {
  path: "/admin/debts/home",
  element: (
    <PrivateRoute
      path="/admin/debts/home"
      roles={[Roles.Admin]}
      Component={DebtsHome}
    />
  ),
};

export const DEBTS_CREATE = {
  path: "/admin/debts/create/:id",
  element: (
    <PrivateRoute
      path="/admin/debts/create/:id"
      roles={[Roles.Admin]}
      Component={DebtsCreate}
    />
  ),
};

export const DEBTS_UPDATE = {
  path: "/admin/debts/update/:id",
  element: (
    <PrivateRoute
      path="/admin/debts/update/:id"
      roles={[Roles.Admin]}
      Component={DebtsUpdate}
    />
  ),
};

export const DEBTS_DETAIL = {
  path: "/admin/debts/detail/:id",
  element: (
    <PrivateRoute
      path="/admin/debts/detail/:id"
      roles={[Roles.Admin]}
      Component={DebtsDetail}
    />
  ),
};

/* Sellers Routes */

export const SELLER_PROFILE = {
  path: "/seller-profile",
  element: (
    <PrivateRoute
      path="/seller-profile"
      roles={[Roles.Admin, Roles.Seller]}
      Component={SellerProfile}
    />
  ),
};

/* Students Routes */

export const STUDENT_PROFILE = {
  path: "/student-profile",
  element: (
    <PrivateRoute
      path="/student-profile"
      roles={[Roles.Admin, Roles.Student]}
      Component={StudentProfile}
    />
  ),
};

export const STUDENT_PROFILE_DETAIL = {
  path: "/student-profile/detail",
  element: (
    <PrivateRoute
      path='/student-profile/detail'
      roles={[Roles.Admin, Roles.Student]}
      Component={ProfileDetail}
    />
  ),
};
