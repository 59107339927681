import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../../../../components/UI/Loader";
import "./styles.scss";

const DebtsDeleteModal = ({ showModal, setShowModal, debt, deleteDebt }) => {
  /* Falta implementar la funcionalidad de eliminar */
  const [loading, setLoading] = useState(false);

  const showLoader = () => {
    deleteDebt(debt);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal();
    }, 2000);
  };

  return (
    <div className="debts-delete-main-container">
      <Modal
        show={showModal}
        onHide={setShowModal}
        backdrop="static"
        keyboard={false}
        centered
        className="debts-delete-modal"
      >
        {!loading ? (
          <>
            <Modal.Header className="debts-delete-modal-header">
              <Modal.Title className="debts-delete-modal-title">
                Eliminar Deuda
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="debts-delete-modal-body">
              <div className="debts-delete-modal-body-data">
                ¿Está seguro que desea eliminar este registro?
              </div>
              <div className="debts-delete-modal-body-data">
                Esta acción no se podrá revertir
              </div>
            </Modal.Body>
            <Modal.Footer className="debts-delete-modal-footer">
              <button
                className="debts-delete-modal-buttons"
                onClick={showLoader}
              >
                Confirmar
              </button>
              <button
                className="debts-delete-modal-buttons"
                onClick={() => setShowModal()}
              >
                Denegar
              </button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header className="debts-delete-modal-header">
              <Modal.Title className="debts-delete-modal-title">
                Eliminando deuda...
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="debts-delete-modal-body">
              <div className="debts-delete-loader-container">
                <Loader />
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default DebtsDeleteModal;
