import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import useUser from "../../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { getOnboardingPresenter } from "../../infrastructure/presentation/presenterProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const Onboarding = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const { user } = useUser();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      lastname: "",
      age: "",
      password: "",
    },
  });

  const viewHandlers = {
    updateStudentDataSuccess(data) {
      console.log("<<<<<<<UPDATE STUDENT>>>>>>>>", data.statusText);
      navigate("/student-profile");
    },
    updateStudentDataError(err) {
      console.log("<<<<<<<UPDATE STUDENT ERROR>>>>>>>>", err);
    },
  };

  const presenter = getOnboardingPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
  }, []);

  const onSubmitForm = (data) => {
    presenter.updateStudentData({
      ...data,
      id: user.id,
      status: 1,
      first_login: false,
    });
  };

  const handleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show);
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  return (
    <div className="onboarding-main-container">
      <div className="onboarding-title-container">
        <div className="onboarding-title">Datos para registrarse</div>
      </div>
      <Container fluid className="onboarding-form-container">
        <Form className="onboarding-form" onSubmit={handleSubmit(onSubmitForm)}>
          {/* name */}
          <Row className="onboarding-form-row">
            <Form.Group
              as={Col}
              className="onboarding-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="col-md-12 mx-auto onboarding-form-label">
                Nombre
              </Form.Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={errors.name}
                      className="onboarding-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.name && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto onboarding-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* lastname */}
          <Row className="onboarding-form-row">
            <Form.Group
              as={Col}
              className="onboarding-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="col-md-12 mx-auto onboarding-form-label">
                Apellido
              </Form.Label>
              <Controller
                name="lastname"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={errors.lastname}
                      className="onboarding-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.lastname && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto onboarding-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* age */}
          <Row className="onboarding-form-row">
            <Form.Group
              as={Col}
              className="onboarding-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="col-md-12 mx-auto onboarding-form-label">
                Edad
              </Form.Label>
              <Controller
                name="age"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.age}
                      className="onboarding-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.age && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto onboarding-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* password */}
          <Row className="onboarding-form-row">
            <Form.Group
              as={Col}
              className="onboarding-form-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="col-md-12 mx-auto onboarding-form-label">
                Nueva contraseña
              </Form.Label>
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 8 }}
                render={({ field }) => (
                  <Stack
                    direction="horizontal"
                    className="col-md-12 mx-auto"
                    gap={1}
                  >
                    <Form.Control
                      {...field}
                      type={inputType}
                      placeholder="********"
                      isInvalid={errors.password}
                      className="onboarding-form-psw-input"
                    />
                    <button
                      onClick={handleShowPassword}
                      variant="outline-secondary"
                      id="button-addon1"
                      className="onboarding-form-psw-button"
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          color="#FFFF"
                          size="2x"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEye} color="#FFFF" size="2x" />
                      )}
                    </button>
                  </Stack>
                )}
              />
              {errors.password?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto onboarding-form-input-error"
                >
                  El campo password es obligatorio
                </Stack>
              )}
              {errors.password?.type === "minLength" && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto onboarding-form-input-error"
                >
                  La contraseña debe tener al menos 8 caracteres
                </Stack>
              )}
            </Form.Group>
          </Row>

          <div className="onboarding-form-buttons-container">
            <button className="onboarding-form-submit-button">Confirmar</button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default Onboarding;
