import WahlMagic from "../../../../assets/WahlMagic.png";
import WahlSenior from "../../../../assets/WahlSenior.png";
import AndisSlim from "../../../../assets/AndisSlim.png";
import AndisPro from "../../../../assets/AndisPro.png";
import ItemMaquina from "./ItemMaquina";

const Maquinas = () => {
  const maquinas = [
    {
      marca: "Wahl",
      nombre: "Magic Clip",
      image: WahlMagic,
      orientacion: "left",
      id: 1,
    },
    {
      marca: "Wahl",
      nombre: "Senior",
      image: WahlSenior,
      id: 2,
    },
    {
      marca: "Andis",
      nombre: "Slimline Pro Li",
      image: AndisSlim,
      orientacion: "left",
      id: 3,
    },
    {
      marca: "Andis",
      nombre: "ProFoil Lithium",
      image: AndisPro,
      id: 4,
    },
  ];

  return (
    <div style={{ backgroundColor: "transparent"}}>
      {maquinas.map((maquina) => (
        <ItemMaquina maquina={maquina} key={maquina.id} />
      ))}
    </div>
  );
};

export default Maquinas;
