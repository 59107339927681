import axios from "axios";

export const HttpUpdateStudentGateway = () => {
  return {
    updateStudent: async (data) => {
      const { id, name, lastname, age, password, status } = data;
      const body = {
        id,
        name,
        lastname,
        age: Number(age),
        password,
        status: Number(status),
      };

      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/student/update`,
        body
      );
    },
  };
};
