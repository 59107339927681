export const SellersLoginPresenter = (getSellersLoginGateway, viewHandlers) => {
  return {
    present() {
      console.log("Sellers login was rendered!");
    },
    loginSeller(data) {
      const loginSellerPromise = getSellersLoginGateway.sellersLogin(data);

      loginSellerPromise
        .then((data) => {
          viewHandlers.loginSellerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.loginSellerError(err);
        });
    },
  };
};
