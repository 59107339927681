import axios from "axios";

export const HttpUpdateCourseGateway = () => {
  return {
    updateCourse: async (data) => {
      const {
        id,
        career_id,
        start_date,
        schedule,
        quota,
	current_quota,
        price,
        discount,
        discount_uses_tools,
      } = data;

      const body = {
        id,
        career_id: Number(career_id),
        start_date,
        schedule,
        quota: Number(quota),
	current_quota: Number(current_quota),
        price: Number(price),
        discount: Number(discount),
        discount_uses_tools: Number(discount_uses_tools),
      };
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/course/update`,
        body
      );
    },
  };
};
