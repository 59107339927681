import React from "react";
import { Image } from "react-bootstrap";
import Logo from "../../../../assets/TBENSENA_BLANCO.svg";
import "./styles.scss";

const LoginHeader = () => {
  return (
    <div className="login-header-main-container">
      <header className="login-header-inner-container">
        <div>
          <Image
            src={Logo}
            alt="TaxiBarber-logo"
            fluid
            width={500}
            height="auto"
          />
        </div>
      </header>
    </div>
  );
};

export default LoginHeader;
