import * as React from "react";

const DropdownIcon = ({ showOpen }) => {
  return (
    <svg width={25} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" style={!showOpen ? {
      transfrom: 'rotate(0deg)',  transition: 'all 0.5s'
    } : {
      transform: 'rotate(180deg)', transition: 'all 0.5s'
    }}>
      <path
        d="m1 1 12.204 14L24 1"
        stroke={!showOpen ? "#BEBFC2" : "#FDBEF3"}
        strokeWidth={2}
      />
    </svg>
  );
};

export default DropdownIcon;
