import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import "./styles.scss";
import ActionButtons from "../../components/ActionButtons";
import { useNavigate } from "react-router-dom";
import {
  getStudentDeletePresenter,
  getStudentsHomePresenter,
} from "./../../infrastructure/presentation/presenterProvider";
import StudentDelete from "./../../components/StudentDeleteModal/index";

const StudentsHome = () => {
  const [students, setStudents] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [studentClicked, setStudentClicked] = useState(null);

  const viewHandlers = {
    getStudentsSucces(data) {
      console.log("<<<<STUDENTS>>>>", data.statusText);
      setStudents(data.data.data);
    },
    getStudentsError(err) {
      console.log("<<<<Home ERROR>>>>", err);
    },
  };

  const deleteViewHandlers = {
    deleteStudentSuccess(data) {
      console.log("<<<<<<ESTUDIANTE ELIMINADO>>>>>>>", data.statusText);
    },
    deleteStudentError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getStudentsHomePresenter(viewHandlers);

  const deletePresenter = getStudentDeletePresenter(deleteViewHandlers);

  const onDelete = (id) => {
    deletePresenter.deleteStudent(id);
  };

  useEffect(() => {
    presenter.present();
    presenter.getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    deletePresenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  return (
    <div className="students-home-main-container">
      <div className="student-home-title">Estudiantes</div>
      <div className="students-home-add-button-container">
        <Button
          className="students-home-add-button"
          variant="outline-light"
          onClick={() => navigate("/admin/register-student")}
        >
          Registrar Estudiante
        </Button>
        <Button
          className="students-home-add-button"
          variant="outline-light"
          onClick={() => navigate("/admin/debts/home")}
        >
          Ver deudas
        </Button>
      </div>
      <div className="students-home-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="students-home-table"
        >
          <thead className="students-home-table-header">
            <tr className="students-home-table-row">
              <th className="students-home-column-title">Id</th>
              <th className="students-home-column-title">Nombre</th>
              <th className="students-home-column-title">Apellido</th>
              <th className="students-home-column-title">DNI</th>
              <th className="students-home-column-title">Email</th>
              <th className="students-home-column-title">Edad</th>
              <th className="students-home-column-title">Acciones</th>
            </tr>
          </thead>
          <tbody className="students-home-table-body">
            {students.map((student) => {
              return (
                <tr className="students-home-row" key={student.id}>
                  <td className="students-home-column-data">{student.id}</td>
                  <td className="students-home-column-data">{student.name}</td>
                  <td className="students-home-column-data">
                    {student.lastname}
                  </td>
                  <td className="students-home-column-data">
                    {student.document_number}
                  </td>
                  <td className="students-home-column-data">{student.email}</td>
                  <td className="students-home-column-data">{student.age}</td>
                  <td className="students-home-column-data">
                    <ActionButtons
                      id={student.id}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      setStudent={setStudentClicked}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <StudentDelete
          showModal={showModal}
          setShowModal={setShowModal}
          deleteStudent={onDelete}
          student={studentClicked}
        />
      )}
    </div>
  );
};
export default StudentsHome;
