import axios from "axios";

export const HttpUpdateCareerGateway = () => {
  return {
    updateCareer: async (data) => {
      const {
        id,
        name,
        duration,
        introduction,
        requirements,
        program_url,
        level,
      } = data;
      const body = {
        id,
        name,
        duration: Number(duration),
        introduction,
        requirements,
        program_url,
        level: Number(level),
      };
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/career/update`,
        body
      );
    },
  };
};
