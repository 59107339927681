import React from "react";
import separador from "../../../../assets/tijera_separador_desktop.png";
import "./styles.scss";

const Separator = () => {
  return (
    <div className="divider">
      <img src={separador} alt="alt" />
    </div>
  );
};

export default Separator;
