export const ProductsPresenter = (
  getAllCareersGateway,
  getAllCoursesGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Products was rendered!");
    },
    getAllCareers() {
      const getAllCareersPromise = getAllCareersGateway.getCareers();

      getAllCareersPromise
        .then((data) => {
          viewHandlers.getAllCareersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllCareersError(err);
        });
    },
    getAllCourses() {
      const getAllCoursesPromise = getAllCoursesGateway.getCourses();

      getAllCoursesPromise
        .then((data) => {
          viewHandlers.getAllCoursesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllCoursesError(err);
        });
    },
  };
};
