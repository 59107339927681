import React, { createContext, useState } from "react";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [jwt, setJwt] = useState(() => window.localStorage.getItem("jwt"));
  const [user, setUser] = useState(() =>
    JSON.parse(window.localStorage.getItem("userData"))
  );
  const [status, setStatus] = useState({ loading: false, error: false });
  const [decoded, setDecoded] = useState({});

  const data = {
    jwt,
    setJwt,
    user,
    setUser,
    status,
    setStatus,
    decoded,
    setDecoded,
  };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export { UserContextProvider };

export default UserContext;
