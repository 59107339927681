export const StudentLoginPresenter = (getLoginGateway, viewHandlers) => {
  return {
    present() {
      console.log("Login was rendered!");
    },
    logIn(data) {
      const loginPromise = getLoginGateway.logIn(data);

      loginPromise
        .then((data) => {
          viewHandlers.loginSuccess(data);
        })
        .catch((err) => {
          viewHandlers.loginError(err);
        });
    },
  };
};
