import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.scss";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/logo_200px.png";
import useUser from "../../../hooks/useUser";
import CustomNav from "../components/Nav";

export default function NavBar() {
  const location = useLocation();
  const { isLogged, logout, user } = useUser();

  return (
    <Navbar expand="lg" className="navbar-main-container">
      <Container className="navbar-inner-container">
        <div className="navbar-inner-container-item-logo">
          <Link to={"/"} className="link-unstyled">
            <Navbar.Brand className="navbar-logo">
              <img
                src={Logo}
                alt="Taxibarber enseña"
                className="navbar-logo-img"
              />
            </Navbar.Brand>
          </Link>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="navbar-inner-container-item-nav">
            <CustomNav
              location={location}
              user={user}
              isLogged={isLogged}
              logout={logout}
            />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
