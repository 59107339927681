import axios from "axios";

export const HttpCreateCareerGateway = () => {
  return {
    createCareer: async (data) => {
      const { name, level, introduction, requirements, duration, program_url } =
        data;
      const body = {
        name,
        level: Number(level),
        introduction,
        requirements,
        duration: Number(duration),
        program_url,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/career/create`,
        body
      );
    },
  };
};
