import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CareersForm from "../../components/CareersForm";
import { getCareersUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const CareersEdit = () => {
  const { id } = useParams();
  const [career, setCareer] = useState({});

  const viewHandlers = {
    getCareerToUpdateSuccess(data) {
      console.log("<<<<<<CAREER BY ID>>>>>>>", data.statusText);
      setCareer(data.data.data);
    },
    getCareerToUpdateError(err) {
      console.log("<<<<<<CAREER BY ID ERROR>>>>>>>", err);
    },
    executeCareerUpdateSuccess(data) {
      console.log("<<<<<<UPDATED CAREER>>>>>>>", data.statusText);
    },
    executeCareerUpdateError(err) {
      console.log("<<<<<<UPDATED CAREER ERROR>>>>>>>", err);
    },
  };

  const presenter = getCareersUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getCareerToUpdate(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCareer = (data) => {
    presenter.executeCareerUpdate(data);
  };

  return (
    <div className="careers-edit-main-container">
      <div className="careers-edit-title-container">
        <div className="careers-edit-screen-title">Editar Carrera</div>
      </div>
      <hr className="divider" />
      <div className="careers-edit-form-container">
        <CareersForm update={updateCareer} career={career} idCareer={id} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default CareersEdit;
