export const SaleCreatePresenter = (createSaleGateway, viewHandlers) => {
  return {
    present() {
      console.log("CreateSale was rendered!");
    },
    createSale(data) {
      const createSalePromise = createSaleGateway.createSale(data);

      createSalePromise
        .then((res) => {
          viewHandlers.createSaleSuccess(res);
        })
        .catch((err) => {
          viewHandlers.createSaleError(err);
        });
    },
  };
};
