import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./styles.scss";

const statusOptions = [
  {
    label: "Pendiente",
    value: "pending",
  },
  {
    label: "Pagada",
    value: "payed",
  },
  {
    label: "Cancelada",
    value: "cancelled",
  },
];

const DebtsForm = ({ inscriptions, create, debt, update }) => {
  const [selected, setSelected] = useState({});
  const [sale, setSale] = useState({});
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sale_id: 0,
      student_id: 0,
      inscription_id: 0,
      amount: 0,
      comment: "",
      status: "",
    },
  });

  const handleSelectChange = ({ value }) => {
    setSelected(value);
  };

  const handleStatusChange = ({ value }) => {
    setValue("status", value);
  };

  const onSubmit = (data) => {
    debt === undefined
      ? create(data)
      : update({ ...data, id: Number(debt.id) });
    navigate("/admin/debts/home");
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (debt !== undefined) {
      setSale(debt.sale);
      setValue("sale_id", debt.sale_id);
      setValue("student_id", debt.student_id);
      setValue("inscription_id", debt.inscription_id);
      setValue("amount", debt.amount);
      setValue("comment", debt.comment);
      setValue("status", debt.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debt]);

  useEffect(() => {
    if (selected) {
      setValue("sale_id", selected.sale_id);
      setValue("student_id", selected.student_id);
      setValue("inscription_id", selected.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="debts-form-main-container">
      <Container fluid className="debts-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmit)} className="debts-form">
          {/* 1 */}
          {debt !== undefined ? (
            <>
              <Row className="debts-form-grid-row">
                <Form.Group
                  as={Col}
                  className="debts-form-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Form.Label className="debts-form-input-label">
                    Item seleccionado
                  </Form.Label>
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      disabled
                      readOnly
                      value={sale && sale.detail}
                      type="text"
                      className="debts-form-input"
                    />
                  </Stack>
                </Form.Group>
              </Row>
            </>
          ) : (
            <>
              <Row className="debts-form-grid-row">
                <Form.Group
                  as={Col}
                  className="debts-form-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Form.Label className="debts-form-input-label">
                    Seleccione una carrera
                  </Form.Label>
                  <Select
                    placeholder="Seleccione una carrera..."
                    className="debts-form-select"
                    options={inscriptions.map((ins, index) => ({
                      label: ins.course.career.name,
                      value: ins,
                    }))}
                    onChange={handleSelectChange}
                  />
                </Form.Group>
              </Row>
            </>
          )}
          {/* sale_id */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">
                ID de la venta
              </Form.Label>
              <Controller
                name="sale_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      readOnly={true}
                      isInvalid={errors.sale_id}
                      className="debts-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.sale_id?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto debts-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>
          {/* student_id */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">
                ID del estudiante
              </Form.Label>
              <Controller
                name="student_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      readOnly={true}
                      isInvalid={errors.student_id}
                      className="debts-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.student_id?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto debts-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* inscription_id */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">
                ID de la inscripción
              </Form.Label>
              <Controller
                name="inscription_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      readOnly={true}
                      isInvalid={errors.inscription_id}
                      className="debts-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.inscription_id?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto debts-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* amount */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">
                Monto de la deuda
              </Form.Label>
              <Controller
                name="amount"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      isInvalid={errors.amount}
                      className="debts-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.amount?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto debts-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* comment */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">
                Comentario
              </Form.Label>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-6 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      className="debts-form-input"
                    />
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>

          {/* status */}
          <Row className="debts-form-grid-row">
            <Form.Group
              as={Col}
              className="debts-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="debts-form-input-label">Estado</Form.Label>
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="debts-form-select"
                    placeholder="Seleccione un estado..."
                    options={statusOptions}
                    onChange={handleStatusChange}
                  />
                )}
              />
              {errors.status?.type === "required" && (
                <Stack
                  direction="horizontal"
                  className="col-md-6 mx-auto debts-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* BOTONES */}
          <div className="debts-form-buttons-container">
            <button onClick={goBack} className="debts-form-back-btn">
              Volver
            </button>
            <button type="submit" className="debts-form-submit-btn">
              {debt === undefined ? "Crear" : "Editar"}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default DebtsForm;
