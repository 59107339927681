export const CourseCreatePresenter = (createCourseGateway, viewHandlers) => {
  return {
    present() {
      console.log("CourseCreate was rendered!");
    },
    createCourse(data) {
      const courseCreatePromise = createCourseGateway.createCourse(data);

      courseCreatePromise
        .then((res) => {
          viewHandlers.createCourseSuccess(res);
        })
        .catch((err) => {
          viewHandlers.createCourseError(err);
        });
    },
  };
};
