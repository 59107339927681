import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import NavBar from "./modules/Navbar/views/NavBar";
import HomeLanding from "../src/modules/landing/views/HomeLanding/index";
import Cart from "./modules/Cart/Cart/Cart";
import Herramientas from "./modules/CourseTools/views/Herramientas";
import CartProvider from "./context/CartContext";
import LoginSelector from "./modules/Login/views/LoginSelector";
import Onboarding from "./modules/Login/views/Onboarding";
import StudentsLogin from "./modules/Login/views/StudentsLogin/index";
import SellersLogin from "./modules/Login/views/SellersLogin";
import { UserContextProvider } from "./context/index";

import CreateSale from "./modules/Sales/views/CreateSale";
import Footer from "./modules/Footer/views/Footer";

import * as webAppRoutes from "./constants/webAppRoutes";
import ProductDetail from "./modules/Products/views/ProductDetail";
import SaleStatus from "./modules/Sales/views/SaleStatus";

function App() {
  return (
    <div className="app">
      <UserContextProvider>
        <CartProvider>
          <BrowserRouter>
            <NavBar />
            <Routes>
              <Route index path="/" element={<HomeLanding />} />
              <Route path="/academy-testing" element={<HomeLanding />} />
              {/* cursos */}
              <Route
                path={webAppRoutes.COURSES_HOME.path}
                element={webAppRoutes.COURSES_HOME.element}
              />
              <Route
                path={webAppRoutes.COURSES_CREATE.path}
                element={webAppRoutes.COURSES_CREATE.element}
              />
              <Route
                path={webAppRoutes.COURSES_DETAIL.path}
                element={webAppRoutes.COURSES_DETAIL.element}
              />
              <Route
                path={webAppRoutes.COURSES_UPDATE.path}
                element={webAppRoutes.COURSES_UPDATE.element}
              />
              {/* carreras */}
              <Route
                path={webAppRoutes.CAREERS_HOME.path}
                element={webAppRoutes.CAREERS_HOME.element}
              />
              <Route
                path={webAppRoutes.CAREERS_CREATE.path}
                element={webAppRoutes.CAREERS_CREATE.element}
              />
              <Route
                path={webAppRoutes.CAREERS_DETAIL.path}
                element={webAppRoutes.CAREERS_DETAIL.element}
              />
              <Route
                path={webAppRoutes.CAREERS_UPDATE.path}
                element={webAppRoutes.CAREERS_UPDATE.element}
              />
              {/* vendedores */}
              <Route
                path={webAppRoutes.SELLERS_HOME.path}
                element={webAppRoutes.SELLERS_HOME.element}
              />
              <Route
                path={webAppRoutes.SELLERS_CREATE.path}
                element={webAppRoutes.SELLERS_CREATE.element}
              />
              <Route
                path={webAppRoutes.SELLERS_DETAIL.path}
                element={webAppRoutes.SELLERS_DETAIL.element}
              />
              <Route
                path={webAppRoutes.SELLERS_UPDATE.path}
                element={webAppRoutes.SELLERS_UPDATE.element}
              />
              {/* estudiantes */}
              <Route
                path={webAppRoutes.STUDENTS_HOME.path}
                element={webAppRoutes.STUDENTS_HOME.element}
              />
              <Route
                path={webAppRoutes.STUDENTS_CREATE.path}
                element={webAppRoutes.STUDENTS_CREATE.element}
              />
              <Route
                path={webAppRoutes.STUDENTS_DETAIL.path}
                element={webAppRoutes.STUDENTS_DETAIL.element}
              />
              <Route
                path={webAppRoutes.STUDENTS_UPDATE.path}
                element={webAppRoutes.STUDENTS_UPDATE.element}
              />
              <Route
                path={webAppRoutes.SELLER_PROFILE.path}
                element={webAppRoutes.SELLER_PROFILE.element}
              />
              <Route
                path={webAppRoutes.STUDENT_PROFILE.path}
                element={webAppRoutes.STUDENT_PROFILE.element}
              />
              <Route
                path={webAppRoutes.STUDENT_PROFILE_DETAIL.path}
                element={webAppRoutes.STUDENT_PROFILE_DETAIL.element}
              />

              {/* Deudas */}

              <Route
                path={webAppRoutes.DEBTS_HOME.path}
                element={webAppRoutes.DEBTS_HOME.element}
              />

              <Route
                path={webAppRoutes.DEBTS_CREATE.path}
                element={webAppRoutes.DEBTS_CREATE.element}
              />

              <Route
                path={webAppRoutes.DEBTS_DETAIL.path}
                element={webAppRoutes.DEBTS_DETAIL.element}
              />

              <Route
                path={webAppRoutes.DEBTS_UPDATE.path}
                element={webAppRoutes.DEBTS_UPDATE.element}
              />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/login" element={<LoginSelector />} />
              <Route path="/login-student" element={<StudentsLogin />} />
              <Route path="/login-seller" element={<SellersLogin />} />
              <Route path="/herramientas" element={<Herramientas />} />
              <Route path="/product/detail/:id" element={<ProductDetail />} />
              <Route
                path="*"
                element={
                  <div style={{ backgroundColor: "red" }}>
                    ERROR 404 NOT FOUND
                  </div>
                }
              />
              <Route path="/cart" element={<Cart />} />
              <Route path="/sale" element={<CreateSale />} />
              <Route
                path="/payment-feedback/success"
                element={<SaleStatus paymentStatus={"success"} />}
              />
              <Route
                path="/payment-feedback/failure"
                element={<SaleStatus paymentStatus={"failure"} />}
              />
              <Route
                path="/payment-feedback/pending"
                element={<SaleStatus paymentStatus={"pending"} />}
              />
            </Routes>
            <Footer />
          </BrowserRouter>
        </CartProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
