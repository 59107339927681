import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./styles.scss";

const DebtModal = ({ show, handleClose, name, amount, link }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header style={{ backgroundColor: "#bb2d3b", color: "white" }}>
        <Modal.Title>AVISO DEUDA</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "bold"}}>
        ¡Hola, {name}! 
        <br/>
        Se han registrado deudas por el monto de <span >${amount}</span>. Puedes abonar el pago
        haciendo <Alert.Link href={link} target="_blank">click aquí</Alert.Link>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#bb2d3b", color: "white", fontWeight: "bold" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DebtModal;
