import React, { useState, useEffect } from "react";
import AccordionDetails from "../../components/AccordionDetails";
import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import "./styles.scss";
import { getStudentProfilePresenter } from "../../infrastructure/presentation/PresenterProvider";
import useUser from "../../../../hooks/useUser";
import DebtModal from "../../components/DebtModal";

const StudentProfile = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [studentDNI, setStudentDNI] = useState(null);
  const [inscriptions, setInscriptions] = useState([]);
  const [debt, setDebt] = useState({});
  const [careers, setCareers] = useState([]);
  const [showModal, setShowModal] = useState(true);

  const viewHandlers = {
    getAllStudentInscriptionsSuccess(data) {
      console.log("<<<<<<STUDENT INSCRIPTIONS>>>>>>>: ", data.statusText);
      setInscriptions(data.data.data);
    },
    getAllStudentInscriptionsError(err) {
      console.log("<<<<<<STUDENT INSCRIPTIONS ERROR>>>>>>>: ", err);
    },
    getStudentSuccess(data) {
      console.log("<<<<<<STUDENT DATA>>>>>>>", data.statusText);
      setStudentDNI(data.data.data.document_number);
    },
    getStudentError(err) {
      console.log("<<<<<<STUDENT ERROR>>>>>>>", err);
    },
    getDebtSuccess(data) {
      console.log("<<<<<<DEBT DETAIL>>>>>>>: ", data.statusText);
      setDebt(data.data.data);
    },
    getDebtError(err) {
      console.log("<<<<<<DEBT ERROR>>>>>>>: ", err);
    },
  };

  const presenter = getStudentProfilePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllStudentInscriptions(user.id);
    presenter.getStudentById(user.id);
    presenter.getDebtById(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const coursesArr = [];
    inscriptions.map((item) => {
      coursesArr.push(item.course.career);
    });
    setCareers(coursesArr);
  }, [inscriptions]);

  const toDetail = (ins) => {
    navigate("/student-profile/detail", {
      state: { inscription: { ...ins, studentDNI: studentDNI }, list: careers },
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div className="profile-detail-main-container">
      {debt.length > 0 ? (
        <div className="profile-detail-modal-alert">
          <Button
            variant="danger"
            onClick={handleOpenModal}
            className="full-width-button"
          >
            ¡DEUDA PENDIENTE!
          </Button>
          <DebtModal
            show={showModal}
            handleClose={handleCloseModal}
            name={user.name}
            Debt={debt}
            amount={debt[0].amount}
            link={debt[0].url_payment}
          />
        </div>
      ) : null}{" "}
      <div className="profile-detail-title-container">
        <div className="profile-detail-title">
          Estudiante: {`${user.name + " " + user.lastname}`}
        </div>
      </div>
      <div className="student-detail-my-courses">
        <h1>Mi Aprendizaje</h1>
        <div className="student-detail-my-courses-accordion">
          <AccordionDetails user={user} />
        </div>
      </div>
      <div className="student-profile-table-title">Mis Cursos</div>
      <div className="student-profile-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="student-profile-table"
        >
          <thead className="student-profile-table-header">
            <tr className="student-profile-table-row">
              <th className="student-profile-column-title">Curso</th>
              <th className="student-profile-column-title">Fecha de inicio</th>
              <th className="student-profile-column-title">Detalles</th>
            </tr>
          </thead>
          <tbody className="student-profile-table-body">
            {inscriptions.map((inscription, index) => {
              return (
                <tr className="student-profile-row" key={index}>
                  <td className="student-profile-column-data-course">
                    {inscription.course.career.name}
                  </td>
                  <td className="student-profile-column-startdate-course">
                    {inscription.course.start_date}
                  </td>
                  <td className="student-profile-column-data-progress">
                    <button
                      className="student-profile-detail-button"
                      onClick={() => toDetail(inscription)}
                    >
                      Ver Detalle
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StudentProfile;
