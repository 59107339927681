import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getCourseDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const DetailCourse = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [career, setCareer] = useState({});
  const navigate = useNavigate();

  const viewHandlers = {
    getCourseSuccess(data) {
      console.log("<<<<<<COURSE DETAIL>>>>>>>", data.statusText);
      setCourse(data.data.data);
      setCareer(data.data.data.career);
    },
    getCourseError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getCourseDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getCourseById(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="course-detail-main-container">
      {course !== undefined ? (
        <>
          <div className="course-detail-title-container">
            <div className="course-detail-title">{`${career.name} (Comisión)`}</div>
          </div>
          <Container fluid className="course-detail-grid-container">
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Precio</div>
                  <div className="course-detail-data">{`$ ${course.price}`}</div>
                </div>
              </Col>
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Nivel</div>
                  <div className="course-detail-data">{career.level}</div>
                </div>
              </Col>
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">
                    Cupos disponibles
                  </div>
                  <div className="course-detail-data">
                    {course.current_quota}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">
                    Descuento por código
                  </div>
                  <div className="course-detail-data">{`${course.discount} %`}</div>
                </div>
              </Col>
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">
                    Descuento por herramientas
                  </div>
                  <div className="course-detail-data">{`${course.discount_uses_tools} %`}</div>
                </div>
              </Col>
            </Row>
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">
                    Fecha de inicio
                  </div>
                  <div className="course-detail-data">{`${course.start_date}`}</div>
                </div>
              </Col>
              <Col className="course-detail-grid-item" sm={12} md={6} lg={6}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Duración</div>
                  <div className="course-detail-data">{`${career.duration} meses`}</div>
                </div>
              </Col>
            </Row>
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={12} lg={12}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Introducción</div>
                  <div
                    className="course-detail-data"
                    style={{ textAlign: "justify", padding: "1% 2%" }}
                  >
                    {career.introduction}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={12} lg={12}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Requerimientos</div>
                  <div className="course-detail-data">
                    {career.requirements}
                  </div>
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row className="course-detail-grid-row">
              <Col className="course-detail-grid-item" sm={12} md={12} lg={12}>
                <div className="course-detail-data-container">
                  <div className="course-detail-data-title">Programa</div>
                  <div className="course-detail-data">
                    <a href={career.program_url} target="_blank" rel="noreferrer">
                      Ver programa
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="course-detail-buttons-container">
              <button
                className="course-detail-buton"
                onClick={() => navigate(-1)}
              >
                Volver
              </button>
              <button
                className="course-detail-buton"
                onClick={() => navigate(`/admin/update-course/${id}`)}
              >
                Editar
              </button>
            </div>
          </Container>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DetailCourse;
