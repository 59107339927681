import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getDebtsDetailPresenter } from "../../infrastructure/presentation/PresenterProvider";
import "./styles.scss";

const DebtsDetail = () => {
  const { id } = useParams();
  const [debt, setDebt] = useState({});
  const [student, setStudent] = useState({});
  const [sale, setSale] = useState({});
  const [inscription, setInscription] = useState({});

  const viewHandlers = {
    getDebtSuccess(data) {
      console.log("<<<<<<DEBT>>>>>>>", data.statusText);
      setDebt(data.data.data);
      setStudent(data.data.data.student);
      setSale(data.data.data.sale);
      setInscription(data.data.data.inscription);
    },
    getDebtError(err) {
      console.log("<<<<<<DEBT ERROR>>>>>>>", err);
    },
  };

  const presenter = getDebtsDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getDebtById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("DEBT: ", debt);
  console.log("STUDENT: ", student);
  console.log("SALE: ", sale);
  console.log("INSCRIPTION: ", inscription);

  return (
    <div className="debts-detail-main-container">
      <div className="debts-detail-title-container">
        <div className="debts-detail-title">{`Deuda de ${student.name} ${student.lastname}`}</div>
      </div>
      <Container fluid className="debts-detail-grid-container">
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Motivo</div>
              <div className="debts-detail-data">{`${sale.detail}`}</div>
            </div>
          </Col>
        </Row>
        <div className="debts-detail-title-container">
          <div className="debts-detail-title">{`Datos de la deuda`}</div>
        </div>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">
                Última actualización
              </div>
              <div className="debts-detail-data">
                {new Date(debt.updated_at).toLocaleDateString()}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Estado</div>
              <div className="debts-detail-data">
                {debt.status === "payed"
                  ? "Pagada"
                  : debt.status === "pending"
                  ? "Pendiente"
                  : debt.status === "cancelled" && "Cancelada"}
              </div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Fecha</div>
              <div className="debts-detail-data">
                {new Date(debt.created_at).toLocaleDateString()}
              </div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Monto</div>
              <div className="debts-detail-data">{`$ ${debt.amount}`}</div>
            </div>
          </Col>
        </Row>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">ID del pago</div>
              <div className="debts-detail-data">
                {debt.payment_id ? `${debt.payment_id}` : "Pendiente"}
              </div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Comentario</div>
              <div className="debts-detail-data">{`${debt.comment}`}</div>
            </div>
          </Col>
        </Row>
        <div className="debts-detail-title-container">
          <div className="debts-detail-title">{`Datos del alumno`}</div>
        </div>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={8} lg={8}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Nombre</div>
              <div className="debts-detail-data">{`${student.name} ${student.lastname}`}</div>
            </div>
          </Col>
        </Row>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Email</div>
              <div className="debts-detail-data">{`${student.email}`}</div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">DNI</div>
              <div className="debts-detail-data">{`${student.document_number}`}</div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Estado del alumno</div>
              <div className="debts-detail-data">
                {student.status ? "Activo" : "Inactivo"}
              </div>
            </div>
          </Col>
        </Row>
        <div className="debts-detail-title-container">
          <div className="debts-detail-title">{`Datos de la venta`}</div>
        </div>
        <Row className="debts-detail-grid-row">
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">ID venta</div>
              <div className="debts-detail-data">{`${sale.id}`}</div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Fecha</div>
              <div className="debts-detail-data">
                {new Date(sale.created_at).toLocaleDateString()}
              </div>
            </div>
          </Col>
          <Col className="debts-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="debts-detail-data-container">
              <div className="debts-detail-data-title">Código utilizado</div>
              <div className="debts-detail-data">
                {sale.referral_code ? `${sale.referral_code}` : "No utilizó"}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DebtsDetail;
