import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

const ActionButtons = ({ id, showModal, setShowModal, setCareer }) => {
  const navigate = useNavigate();

  const ToDetail = () => {
    navigate(`/admin/detail-career/${id}`);
  };
  const ToEdit = () => {
    navigate(`/admin/update-career/${id}`);
  };
  const ToDelete = () => {
    setShowModal(!showModal);
    setCareer(id);
  };

  const AddCourse = () => {
    navigate(`/admin/create-course/${id}`);
  };

  return (
    <Stack
      direction="horizontal"
      className="col-lg-12 mx-auto career-action-buttons-stack"
    >
      <button className="career-action-button" onClick={AddCourse}>
        <FontAwesomeIcon
          icon={faCirclePlus}
          color="#FFF"
          className="career-action-button-icon"
        />
      </button>
      <button className="career-action-button" onClick={ToDetail}>
        <FontAwesomeIcon
          icon={faCircleInfo}
          color="#FFF"
          className="career-action-button-icon"
        />
      </button>
      <button className="career-action-button" onClick={ToEdit}>
        <FontAwesomeIcon
          icon={faPen}
          color="#FFF"
          className="career-action-button-icon"
        />
      </button>
      <button className="career-action-button" onClick={ToDelete}>
        <FontAwesomeIcon
          icon={faTrash}
          color="#FFF"
          className="career-action-button-icon"
        />
      </button>
    </Stack>
  );
};

export default ActionButtons;
