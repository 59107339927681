import React from "react";
import "./styles.scss";
import { Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Roles } from "../../../../constants/roles";

const CustomNav = ({ location, user, isLogged, logout }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    logout();
  };

  if (user === undefined || user === null) {
    return (
      <div className="custom-nav-main-container">
        <Nav className="me-auto custom-nav-inner-container">
          <Nav.Item className="nav-item">
            <NavLink to={"/"}>Inicio</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/herramientas"}>Herramientas</NavLink>
          </Nav.Item>
          {/* <Nav.Item>
            {location.pathname !== "/" ? (
              <NavLink to={"/"}>Cursos</NavLink>
            ) : (
              <a href="#courses">Cursos</a>
            )}
          </Nav.Item> */}
          <Nav.Item>
            {location.pathname !== "/" ? (
              <NavLink to={"/"}>Carreras</NavLink>
            ) : (
              <a href="#careers">Carreras</a>
            )}
          </Nav.Item>
          <div className="nav-login-buttons-container">
            {isLogged ? (
              <>
                <button className="nav-login-button" onClick={handleLogout}>
                  Logout
                </button>
              </>
            ) : (
              <>
                <button className="nav-login-button" onClick={handleLogin}>
                  Login
                </button>
              </>
            )}
          </div>
        </Nav>
      </div>
    );
  } else if (user && user.role === Roles.Admin) {
    return (
      <div className="custom-nav-main-container">
        <Nav className="me-auto custom-nav-inner-container">
          <Nav.Item className="nav-item">
            <NavLink to={"/"}>Inicio</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/admin/all-courses"}>Comisiones</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/admin/all-careers"}>Carreras</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/admin/all-sellers"}>Vendedores</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/admin/all-students"}>Estudiantes</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/seller-profile"}>Perfil</NavLink>
          </Nav.Item>
          <div className="nav-login-buttons-container">
            {isLogged ? (
              <>
                <button className="nav-login-button" onClick={handleLogout}>
                  Logout
                </button>
              </>
            ) : (
              <>
                <button className="nav-login-button" onClick={handleLogin}>
                  Login
                </button>
              </>
            )}
          </div>
        </Nav>
      </div>
    );
  } else if (user && user.role === Roles.Seller) {
    return (
      <div className="custom-nav-main-container">
        <Nav className="me-auto custom-nav-inner-container">
          <Nav.Item className="nav-item">
            <NavLink to={"/"}>Inicio</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/seller-profile"}>Perfil</NavLink>
          </Nav.Item>
          <div className="nav-login-buttons-container">
            {isLogged ? (
              <>
                <button className="nav-login-button" onClick={handleLogout}>
                  Logout
                </button>
              </>
            ) : (
              <>
                <button className="nav-login-button" onClick={handleLogin}>
                  Login
                </button>
              </>
            )}
          </div>
        </Nav>
      </div>
    );
  } else if (user && user.role === Roles.Student) {
    return (
      <div className="custom-nav-main-container">
        <Nav className="me-auto custom-nav-inner-container">
          <Nav.Item className="nav-item">
            <NavLink to={"/"}>Inicio</NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink to={"/student-profile"}>Perfil</NavLink>
          </Nav.Item>
          <div className="nav-login-buttons-container">
            {isLogged ? (
              <>
                <button className="nav-login-button" onClick={handleLogout}>
                  Logout
                </button>
              </>
            ) : (
              <>
                <button className="nav-login-button" onClick={handleLogin}>
                  Login
                </button>
              </>
            )}
          </div>
        </Nav>
      </div>
    );
  }
};

export default CustomNav;
