import React from "react";
import ScrollTop from "./ScrollTop/ScrollTop";
import "./styles.scss";
import ButtonWsp from "./Whatsapp/ButtonWsp";

const LandingButtons = () => {
  return (
    <div className="landing-buttons-container">
      <ScrollTop />
      <ButtonWsp />
    </div>
  );
};

export default LandingButtons;
