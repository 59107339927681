// Gateways
import { HttpGetAllSellersGateway } from "./../gateways/HttpGetAllSellersGateway";
import { HttpCreateSellerGateway } from "./../gateways/HttpCreateSellerGateway";
import { HttpGetSellerGateway } from "./../gateways/HttpGetSellerGateway";
import { HttpUpdateSellerGateway } from './../gateways/HttpUpdateSellerGateway';
import { HttpDeleteSellerGateway } from "./../gateways/HttpDeleteSellerGateway";

// Presenters
import { SellersHomePresenter } from "./SellersHomePresenter";
import { SellerCreatePresenter } from "./SellerCreatePresenter";
import { SellerUpdatePresenter } from "./SellerUpdatePresenter";
import { SellerDetailPresenter } from "./SellerDetailPresenter";
import { SellerDeletePresenter } from "./SellerDeletePresenter";


// Trae todos los estudiantes
export const getSellersHomePresenter = (viewHandlers) => {
  const getHttpGetAllSellersGateway = HttpGetAllSellersGateway();
  return SellersHomePresenter(getHttpGetAllSellersGateway, viewHandlers);
};

// Crea un nuevo estudiante
export const getSellerCreatePresenter = (viewHandlers) => {
  const getHttpCreateSellerGateway = HttpCreateSellerGateway();
  return SellerCreatePresenter(getHttpCreateSellerGateway, viewHandlers);
};

//Trae un solo estudiante por id
export const getSellerDetailPresenter = (viewHandlers) => {
  const getHttpGetSellerGateway = HttpGetSellerGateway();
  return SellerDetailPresenter(getHttpGetSellerGateway, viewHandlers);
};

//Trae un solo estudiante por id para actualizar
export const getSellerUpdatePresenter = (viewHandlers) => {
  const getHttpGetSellerGateway = HttpGetSellerGateway();
  const getHttpUpdateSellerGateway = HttpUpdateSellerGateway();
  return SellerUpdatePresenter(
    getHttpGetSellerGateway,
    getHttpUpdateSellerGateway,
    viewHandlers
  );
};

//Elimina un estudiante por id
export const getSellerDeletePresenter = (viewHandlers) => {
  const getHttpDeleteSellerGateway = HttpDeleteSellerGateway();
  return SellerDeletePresenter(getHttpDeleteSellerGateway, viewHandlers);
};
