import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSellerDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const SellerDetail = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState({});
  const navigate = useNavigate();

  const viewHandlers = {
    getSellerSuccess(data) {
      console.log("<<<<<<SELLER DETAIL>>>>>>>", data.statusText);
      setSeller(data.data.data);
    },
    getSellerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellerDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getSellerById(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="seller-detail-main-container">
      <div className="seller-detail-title-container">
        <div className="seller-detail-title">
          {seller.name + " " + seller.lastname}
        </div>
      </div>
      <Container fluid className="seller-detail-grid-container">
        <Row className="seller-detail-grid-row">
          <Col className="seller-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="seller-detail-data-container">
              <div className="seller-detail-data-title">ID</div>
              <div className="seller-detail-data">{`N°: ${seller.id}`}</div>
            </div>
          </Col>
          <Col className="seller-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="seller-detail-data-container">
              <div className="seller-detail-data-title">Nombre de Usuario</div>
              <div className="seller-detail-data">{`${seller.username}`}</div>
            </div>
          </Col>
        </Row>
        <Row className="seller-detail-grid-row">
          <Col className="seller-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="seller-detail-data-container">
              <div className="seller-detail-data-title">DNI</div>
              <div className="seller-detail-data">{seller.documentNumber}</div>
            </div>
          </Col>
          <Col className="seller-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="seller-detail-data-container">
              <div className="seller-detail-data-title">Email</div>
              <div className="seller-detail-data">{`${seller.email}`}</div>
            </div>
          </Col>
        </Row>
        <Row className="seller-detail-grid-row">
          <Col className="seller-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="seller-detail-data-container">
              <div className="seller-detail-data-title">Código de referido</div>
              <div
                className="seller-detail-data"
                style={{ textAlign: "center", padding: "1% 2%" }}
              >
                {seller.referral_code}
              </div>
            </div>
          </Col>
        </Row>
        <div className="seller-detail-buttons-container">
          <button className="seller-detail-buton" onClick={() => navigate(-1)}>
            Volver
          </button>
          <button
            className="seller-detail-buton"
            onClick={() => navigate(`/admin/update-seller/${id}`)}
          >
            Editar
          </button>
        </div>
      </Container>
    </div>
  );
};

export default SellerDetail;
