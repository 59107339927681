import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "react-bootstrap";

const ActionButtons = ({ id, showModal, setShowModal, setDebt }) => {
  const navigate = useNavigate();

  const toUpdate = () => {
    navigate(`/admin/debts/update/${id}`);
  };

  const toDelete = () => {
    setShowModal(!showModal);
    setDebt(id);
  };

  const toDetail = () => {
    navigate(`/admin/debts/detail/${id}`);
  };

  return (
    <Stack
      direction="horizontal"
      className="col-lg-12 mx-auto debts-action-buttons-stack"
    >
      <button className="debts-action-button" onClick={toDetail}>
        <FontAwesomeIcon
          icon={faCircleInfo}
          color="#FFF"
          className="debts-action-button-icon"
        />
      </button>

      <button className="debts-action-button" onClick={toUpdate}>
        <FontAwesomeIcon
          icon={faPen}
          color="#FFF"
          className="debts-action-button-icon"
        />
      </button>

      <button className="debts-action-button" onClick={toDelete}>
        <FontAwesomeIcon
          icon={faTrash}
          color="#FFF"
          className="debts-action-button-icon"
        />
      </button>
    </Stack>
  );
};

export default ActionButtons;
