export const ProductsDetailPresenter = (
  getCourseGateway,
  getCareerGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("ProductsDetail was rendered!");
    },
    getCourse(id) {
      const getCoursePromise = getCourseGateway.getCourseById(id);

      getCoursePromise
        .then((data) => {
          viewHandlers.getCourseSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCourseError(err);
        });
    },
    getCareer(id) {
      const getCareerPromise = getCareerGateway.getCareerById(id);

      getCareerPromise
        .then((data) => {
          viewHandlers.getCareerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCareerError(err);
        });
    },
  };
};
