export const DebtsDetailPresenter = (getDebtGateway, viewHandlers) => {
  return {
    present() {
      console.log("DebtsDetail was rendered!");
    },
    getDebtById(id) {
      const getDebtPromise = getDebtGateway.getDebt(id);

      getDebtPromise
        .then((data) => {
          viewHandlers.getDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getDebtError(err);
        });
    },
  };
};
