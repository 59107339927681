import React, { useEffect, useState } from "react";
import CustomCarousel from "../../components/CustomCarousel";
import ProductsHeader from "../../components/Intro/ProductsHeader";
import { getProductsPresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const Products = () => {
  // eslint-disable-next-line no-unused-vars
  const [careers, setCareers] = useState([]);
  const [courses, setCourses] = useState([]);

  const viewHandlers = {
    getAllCareersSuccess(data) {
      console.log("<<<<<<CAREERS>>>>>>>", data.statusText);
      setCareers(data.data.data);
    },
    getAllCareersError(err) {
      console.log("<<<<<<CAREERS ERROR>>>>>>>", err);
    },
    getAllCoursesSuccess(data) {
      console.log("<<<<<<COURSES>>>>>>>", data.statusText);
      setCourses(data.data.data);
    },
    getAllCoursesError(err) {
      console.log("<<<<<<COURSES ERROR>>>>>>>", err);
    },
  };

  const presenter = getProductsPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllCareers();
    presenter.getAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="products-container">
      <div className="products-title-container" id="products">
        <div className="products-title">Elegí el tuyo</div>
      </div>
      <div className="products-inner-container">
        <div className="products-header-container" id="careers">
          <ProductsHeader title={"Carreras y cursos"} />
        </div>
        <div className="slide">
          <CustomCarousel items={courses} />
        </div>
      </div>
      {/*  <div className="products-inner-container">
        <div className="products-header-container" id="courses">
          <ProductsHeader title={"Cursos"} />
        </div>
        <div className="slide">
          <CustomCarousel />
        </div>
      </div> */}
    </div>
  );
};

export default Products;
