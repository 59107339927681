import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../../../../components/UI/Loader";
import "./styles.scss";

const CourseDelete = ({ showModal, setShowModal, course, deleteCourse }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => {
    deleteCourse(course);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal();
    }, 2000);
  };

  return (
    <div className="course-delete-main-container">
      <Modal
        show={showModal}
        onHide={setShowModal}
        backdrop="static"
        keyboard={false}
        centered
        className="course-delete-modal"
      >
        {!loading ? (
          <>
            <Modal.Header className="course-delete-modal-header">
              <Modal.Title className="course-delete-modal-title">
                Eliminar Curso
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="course-delete-modal-body">
              <div className="course-delete-modal-body-data">
                ¿Está seguro que desea eliminar este registro?
              </div>
              <div className="course-delete-modal-body-data">
                Esta acción no se podrá revertir
              </div>
            </Modal.Body>
            <Modal.Footer className="delete-course-modal-footer">
              <button
                className="course-delete-modal-buttons"
                onClick={showLoader}
              >
                Confirmar
              </button>
              <button
                className="course-delete-modal-buttons"
                onClick={() => setShowModal()}
              >
                Denegar
              </button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header className="course-delete-modal-header">
              <Modal.Title className="course-delete-modal-title">
                Eliminando curso...
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="course-delete-modal-body">
              <div className="course-delete-loader-container">
                <Loader />
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CourseDelete;
