import axios from "axios";

export const HttpGetDebtGateway = () => {
  return {
    getDebt: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/business/debt/get-all-debts/${id}`
      );
    },
  };
};
