import React, { useState, useEffect, useContext } from "react";
import { AccordionContext } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import DropdownIcon from "../../../assets/icons/DropdownIcon";
import "./styles.scss";

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className="button-accordion"
      onClick={decoratedOnClick}
    >
      <DropdownIcon showOpen={isCurrentEventKey} />
      {children}
    </button>
  );
}
const AccordionDetails = ({ user }) => {
  return (
    <Accordion className="accordion-main-container">
      <Card className="card-container">
        <Card.Header className="accordion-item">
          <h3>Mis datos</h3>
          <CustomToggle eventKey="0"></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="CardBody">
            <div className="grid-container">
              <div className="grid-item">
                <span className="label">Email:</span> {`${user.email}`}
              </div>
              <div className="grid-item">
                <span className="label">Usuario:</span> {`${user.username}`}
              </div>
              <div className="grid-item">
                <span className="label">DNI:</span> {`${user.document_number}`}
              </div>
              <div className="grid-item">
                <span className="label">Rol:</span> {`${user.role}`}
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionDetails;
