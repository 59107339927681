export const StudentProfilePresenter = (
  getStudentInscriptionsGateway,
  getStudentGateway,
  getDebtGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Student profile was rendered!");
    },
    getAllStudentInscriptions(id) {
      const getStudentInscriptionsPromise =
        getStudentInscriptionsGateway.getStudentInscriptions(id);

      getStudentInscriptionsPromise
        .then((data) => {
          viewHandlers.getAllStudentInscriptionsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllStudentInscriptionsError(err);
        });
    },
    getStudentById(id) {
      const getStudentPromise = getStudentGateway.getStudent(id);

      getStudentPromise
        .then((data) => {
          viewHandlers.getStudentSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getStudentError(err);
        });
    },

    getDebtById(id) {
      const getDebtPromise = getDebtGateway.getDebt(id);

      getDebtPromise
        .then((data) => {
          viewHandlers.getDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getDebtError(err);
        });
    },
  };
};
