export const DebtsHomePresenter = (
  getAllDebtsGateway,
  deleteDebtGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("DebtsHome was rendered!");
    },
    getDebts() {
      const getDebtsPromise = getAllDebtsGateway.getAllDebts();

      getDebtsPromise
        .then((data) => {
          viewHandlers.getDebtsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getDebtsError(err);
        });
    },
    deleteDebt(id) {
      const deleteDebtPromise = deleteDebtGateway.deleteDebt(id);

      deleteDebtPromise
        .then((data) => {
          viewHandlers.deleteDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteDebtError(err);
        });
    },
  };
};
