import axios from "axios";

export const HttpDeleteDebtGateway = () => {
  return {
    deleteDebt: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/business/debt/delete/id/${id}`
      );
    },
  };
};
