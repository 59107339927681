import CarouselIntro from "../../components/CustomCarrusel/index";
import BarberTools from "../../components/BarberTools/index";
import Address from "../../components/Location/Pin";
import Map from "../../components/Location/Map";
import LandingButtons from "../../components/LandingButtons";
import "./styles.scss";
import Products from "../../../Products/views/ProductsSection";
import Separator from "../../components/Separator";

const HomeLanding = () => {
  const freeCutMessage = () => {
    console.log("Free cut message");
  };

  return (
    <div className="home-container">
      <a
        href="https://wa.me/2614709741?text=Hola!!%20Quiero%20reservar%20mi%20corte%20gratis!"
        className="home-landing-free-cut-link"
        rel="noreferrer"
        target="_blank"
      >
        <div className="home-landing-free-cut-btn" onClick={freeCutMessage}>
          <div className="free-cut-title">
            Reservá tu corte{" "}
            <span className="free-cut-title-strong">GRATIS</span>
          </div>
        </div>
      </a>
      <h1 className="titulo">
        ¡No te tomamos el <span>PELO</span>!
      </h1>
      <div className="home-landing-description-container">
        <p className="descripcion">
          Con nuestra carrera terminas y trabajas. La experiencia laboral no es
          impedimento para nuestros alumnos. Sé un profesional del rubro
        </p>
      </div>
      <div className="home-landing-button-container">
        <a href="#products" className="all-products-button">
          Ver todos los cursos
        </a>
      </div>
      <section className="home-landing-carrusel-container">
        <CarouselIntro />
      </section>
      <Separator />
      <section className="home-langing-tools-container">
        <BarberTools />
      </section>
      <Separator />
      <LandingButtons />
      <section className="home-landing-products-container">
        <Products />
      </section>
      <Separator />
      <section className="home-landing-address-container">
        <div className="address-title-container">
          <div className="address-title">¿Dónde estamos?</div>
        </div>
        <Address />
        <div className="home-landing-map-container">
          <Map />
        </div>
      </section>
    </div>
  );
};

export default HomeLanding;
