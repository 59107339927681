import axios from "axios";

export const HttpCreateDebtGateway = () => {
  return {
    createDebt: async (data) => {
      const { amount, comment, inscription_id, sale_id, status, student_id } =
        data;
      const body = {
        amount: Number(amount),
        comment,
        inscription_id: Number(inscription_id),
        sale_id: Number(sale_id),
        status,
        student_id: Number(student_id),
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/debt/create`,
        body
      );
    },
  };
};
