export const CareersCreatePresenter = (createCareerGateway, viewHandlers) => {
  return {
    present() {
      console.log("CareerCreate was rendered!");
    },
    createCareer(data) {
      const createCareerPromise = createCareerGateway.createCareer(data);

      createCareerPromise
        .then((data) => {
          viewHandlers.createCareerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.createCareerError(err);
        });
    },
  };
};
