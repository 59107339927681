export const StudentDeletePresenter = (studentDeleteGateway, viewHandlers) => {
  return {
    present() {
      console.log("StudentDelete was rendered!");
    },
    deleteStudent(id) {
      const deleteStudentPromise = studentDeleteGateway.deleteStudent(id);

      deleteStudentPromise
        .then((data) => {
          viewHandlers.deleteStudentSuccess(data);
        })
        .catch((err) => {
          viewHandlers.deleteStudentError(err);
        });
    },
  };
};
