import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Button, Table } from "react-bootstrap";
import "./styles.scss";
import moment from "moment";
import useUser from "../../../../hooks/useUser";
import { getSellerProfilePresenter } from "../../infrastructure/presentation/PresenterProvider";

const SellerProfile = () => {
  const { user } = useUser();
  const [sales, setSales] = useState([]);

  const viewHandlers = {
    getInscriptionsSuccess(data) {
      console.log("<<<<<<INSCRIPCIONES>>>>>>>", data.statusText);
      setSales(data.data.data);
    },
    getInscriptionsError(err) {
      console.log("<<<<<<ERROR INSCRIPCIONES>>>>>>>", err.message);
    },
  };

  const presenter = getSellerProfilePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllInscriptions(user.referral_code);
  }, []);

  // const originalDate = `${sales.created_at}`;

  const originalDate = sales.map((sale) => sale.created_at);
  // const formatedDate = moment(originalDate).format("DD/MM/YYYY");
  const formattedDates = sales.map((sale) => {
    const isoDate = sale.created_at;
    const date = new Date(isoDate);
    return date.toLocaleDateString("es-ES"); // "dd/MM/AAAA"
  });


  // console.log("CANTIDAD VENTAS: ", sales);

  return (
    <div className="profile-detail-main-container">
      <div className="profile-detail-title-container">
        <div className="profile-detail-title">
          ¡Bienvenido, {`${user.name + " " + user.lastname}`}!
        </div>
      </div>
      <Container fluid className="profile-detail-grid-container">
        <Row className="profile-detail-grid-row">
          <Col className="profile-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="profile-detail-data-container">
              <div className="profile-detail-data-title">
                Código de referido
              </div>
              <div
                className="profile-detail-data"
                style={{ textAlign: "center", padding: "1% 2%" }}
              >
                {`${user.referral_code}`}
              </div>
            </div>
          </Col>
          <Col className="profile-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="profile-detail-data-container">
              <div className="profile-detail-data-title">Ventas totales</div>
              <div
                className="profile-detail-data"
                style={{ textAlign: "center", padding: "1% 2%" }}
              >
                {sales.length}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="seller-profile-table-title">Tus ventas</div>
      <div className="seller-profile-table-container">
        <Table
          responsive
          striped
          hover
          variant="dark"
          className="seller-profile-table"
        >
          <thead className="seller-profile-table-header">
            <tr className="seller-profile-table-row">
              <th className="seller-profile-column-title">Curso vendido</th>
              <th className="seller-profile-column-title">Fecha de venta</th>
            </tr>
          </thead>
          <tbody className="seller-profile-table-body">
            {sales.map((sale, index) => {
              return (
                <tr className="seller-profile-row" key={index}>
                  <td className="seller-profile-column-data">{sale.detail}</td>
                  <td className="seller-profile-column-data">
                    {formattedDates[index]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SellerProfile;
