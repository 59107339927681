import axios from "axios";

export const HttpDeleteSellerGateway = () => {
  return {
    deleteSeller: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}/business/seller/delete/id/${id}`
      );
    },
  };
};
