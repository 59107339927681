import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import arrowUp from "../../../../../assets/icons/arrow-up.png";
import "./styles.scss";

const ScrollTop = () => {
  const [scrollButton, setScrollButton] = useState(false);

  const scrollEvent = () => {
    window.pageYOffset > 100 ? setScrollButton(true) : setScrollButton(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {scrollButton && (
        <button
          onClick={backToTop}
          className="scroll-button"
        >
          <img src={arrowUp} alt="" className="scroll-button-icon" />
        </button>
      )}
    </>
  );
};

export default ScrollTop;
