import React, { useEffect } from "react";
import "./styles.scss";
import StudentForm from "../../components/StudentsForm";
import { getStudentCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

const StudentCreate = () => {
  const viewHandlers = {
    createStudentSuccess(data) {
      console.log("<<<<<<STUDENT CREATE>>>>>>>", data.data.message);
    },
    createStudentError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getStudentCreatePresenter(viewHandlers);

  const createNewStudent = (data) => {
    presenter.createStudent(data);
  };

  useEffect(() => {
    presenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student-create-main-container">
      <div className="student-create-title-container">
        <div className="student-create-title">Registrar Estudiante</div>
      </div>
      <hr className="divider" />
      <div className="student-form-container">
        <StudentForm create={createNewStudent} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default StudentCreate;
