import axios from "axios";

export const HttpCreateCourseGateway = () => {
  return {
    createCourse: async (data) => {
      const {
        career_id,
        start_date,
        schedule,
        quota,
        price,
        discount,
        discount_uses_tools,
      } = data;
      const body = {
        career_id: Number(career_id),
        start_date,
        schedule,
        quota: Number(quota),
        price: Number(price),
        discount: Number(discount),
        discount_uses_tools: Number(discount_uses_tools),
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/course/create`,
        body
      );
    },
  };
};
