import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import Logo from "../../../assets/TBENSENA_BLANCO.svg";
import Ubication from "../../../assets/icons/location-dot-solid.svg";
import Facebook from "../../../assets/icons/facebook.svg";
import Instagram from "../../../assets/icons/instagram.svg";

const Footer = () => {
  const navigate = useNavigate();

  const toAdminLogin = () => {
    navigate("/login-seller");
  };

  const urlGoogleMaps =
    "https://www.google.com/maps/place/Taxi+Barber+(Vistalba)/@-33.0317856,-68.9027526,21z/data=!4m15!1m8!3m7!1s0x967e75577bb4bee7:0x61de64ea1bd40117!2sEmbalse+Potrerillos+350,+M5509+Vistalba,+Mendoza!3b1!8m2!3d-33.0317218!4d-68.9027526!16s%2Fg%2F11jgxnw1bj!3m5!1s0x967e75a144532bd7:0x129bbf8a8c6a796d!8m2!3d-33.0317653!4d-68.9027736!16s%2Fg%2F11h07_jk3p";

  return (
    <div className="footer-main-container">
      <img src={Logo} alt="" />
      <div className="icons-container">
        <div className="footer-admin-link" onClick={toAdminLogin}>
          Empresa
        </div>
        <a href={urlGoogleMaps} rel="noreferrer" target="_blank">
          <img src={Ubication} alt="" />
        </a>
        <a
          href={
            "https://www.facebook.com/profile.php?id=100063463963756&mibextid=LQQJ4d"
          }
          rel="noreferrer"
          target="_blank"
        >
          <img src={Facebook} alt="" style={{ cursor: "pointer" }} />
        </a>
        <a
          href="https://instagram.com/taxibarber?igshid=YmMyMTA2M2Y="
          rel="noreferrer"
          target="_blank"
        >
          <img src={Instagram} alt="" style={{ cursor: "pointer" }} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
