export const StudentDetailPresenter = (getStudentGateway, viewHandlers) => {
  return {
    present() {
      console.log("StudentDetail was rendered!");
    },
    getStudentById(id) {
      const getStudentPromise = getStudentGateway.getStudent(id);

      getStudentPromise
        .then((data) => {
          viewHandlers.getStudentSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getStudentError(err);
        });
    },
  };
};
