import "../../views/Herramientas.scss";

const ItemMaquina = ({ maquina }) => {
  const { marca, nombre, image, orientacion } = maquina;

  return (
    <div className="card-maquinas">
      {orientacion ? (
        <div className="d-flex" style={{ backgroundColor: "transparent" }}>
          <img src={image} alt="" style={{ backgroundColor: "transparent" }}/>
          <h1>
            {marca}
            <br />
            {nombre}
          </h1>
        </div>
      ) : (
        <div className="d-flex" style={{ marginLeft: "auto", backgroundColor: "transparent" }}>
          <h1>
            {marca}
            <br />
            {nombre}
          </h1>
          <img src={image} style={{ backgroundColor: "transparent" }} alt="" />
        </div>
      )}
    </div>
  );
};

export default ItemMaquina;
