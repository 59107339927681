import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getStudentDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const StudentDetail = () => {
  const { id } = useParams();
  const [student, setStudent] = useState({});
  const navigate = useNavigate();

  const viewHandlers = {
    getStudentSuccess(data) {
      console.log("<<<<<<Student DETAIL>>>>>>>", data.statusText);
      setStudent(data.data.data);
    },
    getStudentError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getStudentDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getStudentById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student-detail-main-container">
      <div className="student-detail-title-container">
        <div className="student-detail-title">
          {student.name + " " + student.lastname}
        </div>
      </div>
      <Container fluid className="student-detail-grid-container">
        <Row className="student-detail-grid-row">
          <Col className="student-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="student-detail-data-container">
              <div className="student-detail-data-title">DNI</div>
              <div className="student-detail-data">{`${student.document_number}`}</div>
            </div>
          </Col>
          <Col className="student-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="student-detail-data-container">
              <div className="student-detail-data-title">Edad</div>
              <div className="student-detail-data">{`${student.age} años`}</div>
            </div>
          </Col>
          <Col className="student-detail-grid-item" sm={12} md={4} lg={4}>
            <div className="student-detail-data-container">
              <div className="student-detail-data-title">Estado</div>
              <div className="student-detail-data">
                {student.status === 1 ? "Activo" : "Inactivo"}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="student-detail-grid-row">
          <Col className="student-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="student-detail-data-container">
              <div className="student-detail-data-title">Email</div>
              <div
                className="student-detail-data"
                style={{ textAlign: "center", padding: "1% 2%" }}
              >
                {student.email}
              </div>
            </div>
          </Col>
        </Row>
        <div className="student-detail-buttons-container">
          <button className="student-detail-buton" onClick={() => navigate(-1)}>
            Volver
          </button>
          <button
            className="student-detail-buton"
            onClick={() => navigate(`/admin/update-student/${id}`)}
          >
            Editar
          </button>
        </div>
      </Container>
    </div>
  );
};

export default StudentDetail;
