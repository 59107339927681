export const SellerDeletePresenter = (studentDeleteGateway, viewHandlers) => {
    return {
      present() {
        console.log("SellerDelete was rendered!");
      },
      deleteSeller(id) {
        const deleteSellerPromise = studentDeleteGateway.deleteSeller(id);
  
        deleteSellerPromise
          .then((data) => {
            viewHandlers.deleteSellerSuccess(data);
          })
          .catch((err) => {
            viewHandlers.deleteSellerError(err);
          });
      },
    };
  };
  