import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { getCareersDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const CareersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [career, setCareer] = useState({});

  const viewHandlers = {
    getCareerSucces(data) {
      console.log("<<<<<<CAREER DETAIL>>>>>>>", data.statusText);
      setCareer(data.data.data);
    },
    getCareerError(err) {
      console.log("<<<<<<CAREER DETAIL ERROR>>>>>>>", err);
    },
  };

  const presenter = getCareersDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getCareerById(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("CARRERA:", career);

  return (
    <div className="careers-detail-main-container">
      <div className="careers-detail-title-container">
        <div className="careers-detail-title">{career.name}</div>
      </div>
      <Container fluid className="careers-detail-grid-container">
        <Row className="careers-detail-grid-row">
          <Col className="careers-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="careers-detail-data-container">
              <div className="careers-detail-data-title">Duración en meses</div>
              <div className="careers-detail-data">{`${career.duration} meses`}</div>
            </div>
          </Col>
          <Col className="careers-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="careers-detail-data-container">
              <div className="careers-detail-data-title">Nivel</div>
              <div className="careers-detail-data">{`${career.level}`}</div>
            </div>
          </Col>
          <Col className="careers-detail-grid-item" sm={12} md={6} lg={6}>
            <div className="careers-detail-data-container">
              <div className="careers-detail-data-title">Programa</div>
              <div className="careers-detail-data">
                <a href={career.program_url} target="_blank" rel="noreferrer">
                  Ver programa
                </a>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="careers-detail-grid-row">
          <Col className="careers-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="careers-detail-data-container">
              <div className="careers-detail-data-title">Introducción</div>
              <div className="careers-detail-data">{career.introduction}</div>
            </div>
          </Col>
        </Row>

        <Row className="careers-detail-grid-row">
          <Col className="careers-detail-grid-item" sm={12} md={12} lg={12}>
            <div className="careers-detail-data-container">
              <div className="careers-detail-data-title">Requisitos</div>
              <div className="careers-detail-data">{career.requirements}</div>
            </div>
          </Col>
        </Row>

        <div className="careers-detail-buttons-container">
          <button
            className="careers-detail-button"
            onClick={() => navigate(-1)}
          >
            Volver
          </button>
          <button
            className="careers-detail-button"
            onClick={() => navigate(`/admin/update-career/${id}`)}
          >
            Editar
          </button>
        </div>
      </Container>
    </div>
  );
};

export default CareersDetail;
