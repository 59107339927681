import "./styles.scss";

const ProductsHeader = ({ title }) => {
  return (
    <>
      <h2 className="subtitle">{title}</h2>
      <div className="buttons-container">
        <button className="btnBarberia">Barbería</button>
        <div className="button-group">
          <span>Próximamente</span>
          <button disabled>Colorimetría</button>
        </div>
        <div className="button-group">
          <span>Próximamente</span>
          <button disabled>Cejas y pestañas</button>
        </div>
      </div>
    </>
  );
};

export default ProductsHeader;
