import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

const ActionButtons = ({ id, showModal, setShowModal, setStudent }) => {
  const navigate = useNavigate();

  const ToDebts = () => {
    navigate(`/admin/debts/create/${id}`);
  };

  const ToDetail = () => {
    navigate(`/admin/detail-student/${id}`);
  };
  const ToEdit = () => {
    navigate(`/admin/update-student/${id}`);
  };
  const ToDelete = () => {
    setShowModal(!showModal);
    setStudent(id);
  };
  return (
    <Stack
      direction="horizontal"
      className="col-lg-12 mx-auto action-buttons-stack"
    >
      <button className="action-button" onClick={ToDebts}>
        <FontAwesomeIcon
          icon={faClipboardList}
          color="#FFF"
          className="action-button-icon"
        />
      </button>
      <button className="action-button" onClick={ToDetail}>
        <FontAwesomeIcon
          icon={faCircleInfo}
          color="#FFF"
          className="action-button-icon"
        />
      </button>
      <button className="action-button" onClick={ToEdit}>
        <FontAwesomeIcon
          icon={faPen}
          color="#FFF"
          className="action-button-icon"
        />
      </button>
      <button className="action-button" onClick={ToDelete}>
        <FontAwesomeIcon
          icon={faTrash}
          color="#FFF"
          className="action-button-icon"
        />
      </button>
    </Stack>
  );
};

export default ActionButtons;
