import herramienta from "../../../../assets/maquina.png";
import maquinas from "../../../../assets/Group 24.png";
import check from "../../../../assets/check.svg";
import "./styles.scss";
import { Link } from "react-router-dom";

const BarberTools = () => {
  return (
    <div className="barber-tools-container">
      <div className="maquinas1-container">
        <h1>
          No traigas NADA, cursa con <span>TODO</span>
        </h1>
        <img src={herramienta} alt="" />
      </div>
      <div className="maquinas2-container">
        <img src={maquinas} alt="" />
        <div className="sub-div">
          <h1>Te damos las mejores máquinas y herramientas</h1>
          <img src={check} alt="" />
          <Link to={`/herramientas`} className="link-unstyled">
            <button className="all-tools-button">Ver herramientas</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BarberTools;
