import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudentForm from "../../components/StudentsForm";
import { getStudentUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const StudentEdit = () => {
  const { id } = useParams();
  const [student, setStudent] = useState({});

  const viewHandlers = {
    getStudentToUpdSuccess(data) {
      console.log("<<<<<<<STUDENT BY ID>>>>>>>>", data.statusText);
      setStudent(data.data.data);
    },
    getStudentToUpdError(err) {
      console.log("<<<<<<<STUDENT BY ID ERROR>>>>>>>>", err);
    },
    updateStudentSuccess(data) {
      console.log("<<<<<<<UPDATE STUDENT>>>>>>>>", data.statusText);
    },
    updateStudentError(err) {
      console.log("<<<<<<<UPDATE STUDENT>>>>>>>>", err);
    },
  };

  const presenter = getStudentUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getStudentToUpd(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStudent = (data) => {
    presenter.updateStudent(data);
  };

  return (
    <div className="student-edit-main-container">
      <div className="student-edit-title-container">
        <div className="student-edit-title">Actualizar Estudiante</div>
      </div>
      <hr className="divider" />
      <div className="student-edit-form-container">
        <StudentForm update={updateStudent} student={student} idStudent={id} />
      </div>
    </div>
  );
};

export default StudentEdit;
