/* Gateways */
import { HttpGetAllCoursesGateway } from "../gateways/HttpGetAllCoursesGateway";
import { HttpCreateCourseGateway } from "../gateways/HttpCreateCourseGateway";
import { HttpGetCourseGateway } from "../gateways/HttpGetCourseGateway";
import { HttpUpdateCourseGateway } from "../gateways/HttpUpdateCourseGateway";
import { HttpDeleteCourseGateway } from "../gateways/HttpDeleteCourseGateway";

/* Presenters */
import { CourseCreatePresenter } from "./CourseCreatePresenter";
import { CoursesHomePresenter } from "./CoursesHomePresenter";
import { CourseDetailPresenter } from "./CourseDetailPresenter";
import { CourseUpdatePresenter } from "./CourseUpdatePresenter";
import { CourseDeletePresenter } from "./CourseDeletePresenter";

/* Trae todos los cursos existentes */
export const getCoursesHomePresenter = (viewHandlers) => {
  const getHttpGetAllCoursesGateway = HttpGetAllCoursesGateway();
  return CoursesHomePresenter(getHttpGetAllCoursesGateway, viewHandlers);
};

/* Crea un nuevo curso */
export const getCourseCreatePresenter = (viewHandlers) => {
  const getHttpCreateCourseGateway = HttpCreateCourseGateway();
  return CourseCreatePresenter(getHttpCreateCourseGateway, viewHandlers);
};

/* Trae un solo curso por id */
export const getCourseDetailPresenter = (viewHandlers) => {
  const getHttpGetCourseGateway = HttpGetCourseGateway();
  return CourseDetailPresenter(getHttpGetCourseGateway, viewHandlers);
};

/* Busca un curso por id para actualizar */
export const getCourseUpdatePresenter = (viewHandlers) => {
  const getHttpGetCourseGateway = HttpGetCourseGateway();
  const getHttpUpdateCourseGateway = HttpUpdateCourseGateway();
  return CourseUpdatePresenter(
    getHttpGetCourseGateway,
    getHttpUpdateCourseGateway,
    viewHandlers
  );
};

export const getCourseDeletePresenter = (viewHandlers) => {
  const getHttpDeleteCourseGateway = HttpDeleteCourseGateway();
  return CourseDeletePresenter(getHttpDeleteCourseGateway, viewHandlers);
};
