import { StyleSheet, Font } from "@react-pdf/renderer";
import InriaSans from "../../../../assets/fonts/InriaSans-Regular.ttf";

Font.register({
  family: "Inria Sans",
  format: "truetype",
  src: InriaSans,
});

export const styles = StyleSheet.create({
  page: {
    display: "flex",
    justifyContent: "center",
    minWidth: "100%",
    height: "100%",
  },
  svg: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  diplomaPdfOutterWrapper: {
    display: "flex",
    padding: "3.5%",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgb(241, 204, 44, 0)",
  },
  diplomaPdfInnerWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(0, 0, 0, 1)",
    zIndex: 999,
  },
  diplomaPdfImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diplomaPdfImage: {
    width: "40%",
  },
  diplomaPdfStudentDataContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  diplomaPdfStudentNameContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diplomaPdfStudentName: {
    fontFamily: "Inria Sans",
    color: "white",
    fontSize: 40,
    paddingBottom: "1%",
  },
  diplomaPdfStudentDniContainer: {
    textAlign: "center",
    paddingBottom: "2%",
  },
  diplomaPdfStudentDni: {
    fontFamily: "Inria Sans",
    color: "white",
    paddingVertical: "2%",
    fontSize: 25,
  },
  diplomaPdfStudentStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diplomaPdfStatus: {
    fontFamily: "Inria Sans",
    padding: "2%",
    backgroundColor: "rgba(241, 204, 43, 1)",
    fontSize: 25,
  },
  diplomaPdfTitleContainer: {
    marginTop: "5%",
    textAlign: "center",
    overflow: "hidden",
  },
  diplomaPdfTitle: {
    fontFamily: "Inria Sans",
    color: "lightgray",
    fontSize: 47,
    fontWeight: 700,
    paddingBottom: "5%",
  },
  diplomaPdfContentContainer: {
    marginHorizontal: "0.5%",
    paddingHorizontal: "2%",
    textAlign: "center",
    lineHeight: 1.5,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  diplomaPdfContent: {
    fontFamily: "Inria Sans",
    color: "white",
    fontSize: 20,
    lineHeight: "1.7px",
  },
  diplomaPdfSignaturesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "20vh",
  },
  diplomaPdfSignature: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diplomaPdfFirstSignature: {
    borderBottom: "1px solid white",
    width: "80%",
    height: "45%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diplomaPdfSecondSignature: {
    borderBottom: "1px solid white",
    width: "80%",
    height: "45%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  firstSignature: {
    color: "white",
    fill: "#FFF",
    width: "60%",
  },
  secondSignature: {
    color: "white",
    fill: "#FFF",
    width: "35%",
    paddingBottom: "5%",
  },
  diplomaPdfSignatureText: {
    fontFamily: "Inria Sans",
    color: "white",
    marginTop: "3%",
  },
});
