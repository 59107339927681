export const CourseDetailPresenter = (getCourseGateway, viewHandlers) => {
  return {
    present() {
      console.log("CourseDetail was rendered!");
    },
    getCourseById(id) {
      const getCoursePromise = getCourseGateway.getCourse(id);

      getCoursePromise
        .then((data) => {
          viewHandlers.getCourseSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCourseError(err);
        });
    },
  };
};
