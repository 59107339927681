export const DebtsUpdatePresenter = (
  getDebtGateway,
  updateDebtGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("update debt was rendered!");
    },
    getDebt(id) {
      const getDebtPromise = getDebtGateway.getDebt(id);

      getDebtPromise
        .then((data) => {
          viewHandlers.getDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getDebtError(err);
        });
    },
    updateDebt(data) {
      const updateDebtPromise = updateDebtGateway.updateDebt(data);

      updateDebtPromise
        .then((data) => {
          viewHandlers.updateDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateDebtError(err);
        });
    },
  };
};
