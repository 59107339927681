export const StudentsHomePresenter = (getAllStudentsGateway, viewHandlers) => {
  return {
    present() {
      console.log("AllStudents was rendered!");
    },
    getStudents() {
      const getAllStudentsPromise = getAllStudentsGateway.getAllStudents();

      getAllStudentsPromise
        .then((data) => {
          viewHandlers.getStudentsSucces(data);
        })
        .catch((err) => {
          viewHandlers.getStudentsError(err);
        });
    },
  };
};
