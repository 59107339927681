export const SellerDetailPresenter = (getSellerGateway, viewHandlers) => {
    return {
      present() {
        console.log("SellerDetail was rendered!");
      },
      getSellerById(id) {
        const getSellerPromise = getSellerGateway.getSeller(id);
  
        getSellerPromise
          .then((data) => {
            viewHandlers.getSellerSuccess(data);
          })
          .catch((err) => {
            viewHandlers.getSellerError(err);
          });
      },
    };
  };
  