import axios from "axios";

export const HttpGetStudentDataGateway = () => {
  return {
    getStudentData: async (id) => {
      const body = {
        id: Number(id),
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/student/get-inscriptions`,
        body
      );
    },
  };
};
