import "./styles.scss";
import pinUbicacion from "../../../../../assets/pin.svg";

const Address = () => {
  return (
    <div className="address-main-container">
      <div className="pin-container">
        <img src={pinUbicacion} alt="" />
        <div className="pin-text-container">
          <p>Estamos en Luján de Cuyo, a 25’ en micro desde ciudad</p>
        </div>
      </div>
    </div>
  );
};

export default Address;
