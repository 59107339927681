import axios from "axios";

export const HttpGetAllDebtsGateway = () => {
  return {
    getAllDebts: async () => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/business/debt/get-all`
      );
    },
  };
};
