import axios from "axios";

export const HttpUpdateDebtGateway = () => {
  return {
    updateDebt: async (data) => {
      const { id, amount, comment, status } = data;
      const body = {
        id: Number(id),
        amount: Number(amount),
        comment,
        status,
      };
      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/debt/update`,
        body
      );
    },
  };
};
