import { HttpGetDebtGateway } from "../gateways/HttpGetDebtGateway";
import { HttpGetStudentInscriptions } from "../gateways/HttpGetStudentInscriptions";
import { HttpGetStudentGateway } from "../gateways/HttpGetStudentGateway";

import { StudentProfilePresenter } from "./StudentProfilePresenter";

export const getStudentProfilePresenter = (viewHandlers) => {
  const getStudentInscriptionsGateway = HttpGetStudentInscriptions();
  const getStudentGateway = HttpGetStudentGateway();
  const getDebtGateway = HttpGetDebtGateway();
  return StudentProfilePresenter(
    getStudentInscriptionsGateway,
    getStudentGateway,
    getDebtGateway,
    viewHandlers
  );
};
