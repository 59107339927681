export const SellerCreatePresenter = (createSellerGateway, viewHandlers) => {
    return {
      present() {
        console.log("SellerCreate was rendered!");
      },
      createSeller(data) {
        const studentCreatePromise = createSellerGateway.createSeller(data);
  
        studentCreatePromise
          .then((res) => {
            viewHandlers.createSellerSuccess(res);
          })
          .catch((err) => {
            viewHandlers.createSellerError(err);
          });
      },
    };
  };