import axios from "axios";

export const HttpGetInscriptions = () => {
  return {
    getInscriptions: async (referral_code) => {
      const body = {
        referral_code,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/seller/get-sales`,
        body
      );
    },
  };
};
