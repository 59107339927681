// Gateways
import { HttpGetAllStudentsGateway } from "./../gateways/HttpGetAllStudentsGateway";
import { HttpCreateStudentGateway } from "./../gateways/HttpCreateStudentGateway";
import { HttpGetStudentGateway } from "./../gateways/HttpGetStudentGateway";
import { HttpUpdateStudentGateway } from "./../gateways/HtpUpdateStudentGateway";

// Presenters
import { StudentsHomePresenter } from "./StudentsHomePresenter";
import { StudentCreatePresenter } from "./StudentCreatePresenter";
import { StudentUpdatePresenter } from "./StudentUpdatePresenter";
import { HttpDeleteStudentGateway } from "./../gateways/HttpDeleteStudentGateway";
import { StudentDetailPresenter } from "./StudentDetailPresenter";
import { StudentDeletePresenter } from "./StudentDeletePresenter";

// Trae todos los estudiantes
export const getStudentsHomePresenter = (viewHandlers) => {
  const getHttpGetAllStudentsGateway = HttpGetAllStudentsGateway();
  return StudentsHomePresenter(getHttpGetAllStudentsGateway, viewHandlers);
};

// Crea un nuevo estudiante
export const getStudentCreatePresenter = (viewHandlers) => {
  const getHttpCreateStudentGateway = HttpCreateStudentGateway();
  return StudentCreatePresenter(getHttpCreateStudentGateway, viewHandlers);
};

//Trae un solo estudiante por id
export const getStudentDetailPresenter = (viewHandlers) => {
  const getHttpGetStudentGateway = HttpGetStudentGateway();
  return StudentDetailPresenter(getHttpGetStudentGateway, viewHandlers);
};

//Trae un solo estudiante por id para actualizar
export const getStudentUpdatePresenter = (viewHandlers) => {
  const getHttpGetStudentGateway = HttpGetStudentGateway();
  const getHttpUpdateStudentGateway = HttpUpdateStudentGateway();
  return StudentUpdatePresenter(
    getHttpGetStudentGateway,
    getHttpUpdateStudentGateway,
    viewHandlers
  );
};

//Elimina un estudiante por id
export const getStudentDeletePresenter = (viewHandlers) => {
  const getHttpDeleteStudentGateway = HttpDeleteStudentGateway();
  return StudentDeletePresenter(getHttpDeleteStudentGateway, viewHandlers);
};
