export const OnboardingPresenter = (updateStudentGateway, viewHandlers) => {
  return {
    present() {
      console.log("Onboarding screen was rendered!");
    },
    updateStudentData(data) {
      const updateStudentDataPromise = updateStudentGateway.updateStudent(data);

      updateStudentDataPromise
        .then((data) => {
          viewHandlers.updateStudentDataSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateStudentDataError(err);
        });
    },
  };
};
