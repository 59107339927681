export const DebtsCreatePresenter = (
  getStudentDataGateway,
  getCreateDebtGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("DebtsCreate was rendered!");
    },
    getStudentInfo(id) {
      const getStudentInfoPromise = getStudentDataGateway.getStudentData(id);

      getStudentInfoPromise
        .then((data) => {
          viewHandlers.getStudentInfoSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getStudentInfoError(err);
        });
    },
    createDebt(data) {
      const createDebtPromise = getCreateDebtGateway.createDebt(data);

      createDebtPromise
        .then((data) => {
          viewHandlers.createDebtSuccess(data);
        })
        .catch((err) => {
          viewHandlers.createDebtError(err);
        });
    },
  };
};
