import React, { useState, useEffect } from "react";
import "./styles.scss";
import Slider from "react-slick";
import CardItem from "../CardItem/index";

const CustomCarrusel = ({ items }) => {
  const [length, setLength] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    setLength(Array.from(items).length);
    if (Array.from(items).length <= 3) {
      setItemsToShow(length);
    } else {
      setItemsToShow(3);
    }
  }, [items, length]);

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    arrows: true,
    infinite: length >= 3 && true,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: length >= 3,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: length >= 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: length >= 3,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: length >= 3,
        },
      },
    ],
  };

  const slides = items.map((item, index) => {
    return <CardItem item={item} key={index} />;
  });

  return (
    <div className="slider-container">
      <Slider {...settings}>{slides}</Slider>
    </div>
  );
};

export default CustomCarrusel;
