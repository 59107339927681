import axios from "axios";

export const HttpUpdateSellerGateway = () => {
  return {
    updateSeller: async (data) => {
      const {
        id,
        username,
        name,
        lastname,
        email,
        documentNumber,
        referral_code,
      } = data;
      const body = {
        id,
        username,
        name,
        lastname,
        email,
        documentNumber,
        referral_code,
      };

      return axios.put(
        `${process.env.REACT_APP_BASE_URL}/business/seller/update`,
        body
      );
    },
  };
};
