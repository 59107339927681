import { createContext, useState } from "react";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [product, setProduct] = useState(() =>
    JSON.parse(window.localStorage.getItem("product"))
  );
  // eslint-disable-next-line no-unused-vars
  const [cartHasItem, setCartHasItem] = useState(false);

  /* Sumar un producto al carrito */
  const addItem = (item) => {
    setProduct(item);
    /* setCartItems([...cartItems, { item }]); */
    window.localStorage.setItem("product", JSON.stringify(item));
    /* if (!isInCart()) {
      setProduct(item);
      setCartItems([...cartItems, { item }]);
      window.localStorage.setItem("product", JSON.stringify(item));
    }
    setCartHasItem(true); */
  };

  /* Verificar si ya está el producto en el carrito */
  const isInCart = () => {
    if (product !== null) {
      setCartHasItem(true);
      return true;
    } else {
      setCartHasItem(false);
      return false;
    }
  };

  /* Limpiar todo el carrito */
  const clearCart = () => {
    setCartItems([]);
    setCartHasItem(false);
    setProduct(null);
    window.localStorage.removeItem("product");
  };

  /* Borrar un producto del carrito */
  const removeItem = (itemId) =>
    setCartItems(cartItems.filter((element) => element.item.id !== itemId));

  /* Enviar orden al backend */

  /* Calcular el total */
  /* const total = () => {
        return cartItems.reduce((valorAnterior, valorActual) => valorAnterior + valorActual.item.price * valorActual.quantity, 0);
    } */

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addItem,
        isInCart,
        clearCart,
        removeItem,
        cartHasItem: Boolean(product),
        product,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
