import React, { useEffect } from "react";
import CareersForm from "../../components/CareersForm";
import { getCareersCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const CareersCreate = () => {
  const viewHandlers = {
    createCareerSuccess(data) {
      console.log("<<<<<<CAREER CREATE>>>>>>>", data.data.message);
    },
    createCareerError(err) {
      console.log("<<<<<<CAREER CREATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getCareersCreatePresenter(viewHandlers);

  const createNewCareer = (data) => {
    presenter.createCareer(data);
  };

  useEffect(() => {
    presenter.present();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="careers-create-main-container">
      <div className="careers-create-title-container">
        <div className="careers-create-title">Nueva Carrera</div>
      </div>
      <hr className="divider" />
      <div className="career-form-container">
        <CareersForm create={createNewCareer} />
      </div>
      <hr className="divider" />
    </div>
  );
};

export default CareersCreate;
