import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SellerForm from "./../../components/SellerForm/index";
import { getSellerUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import "./styles.scss";

const SellerEdit = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState({});

  const viewHandlers = {
    getSellerToUpdSuccess(data) {
      console.log("<<<<<<<STUDENT BY ID>>>>>>>>", data.statusText);
      setSeller(data.data.data);
    },
    getSellerToUpdError(err) {
      console.log("<<<<<<<STUDENT BY ID ERROR>>>>>>>>", err);
    },
    updateSellerSuccess(data) {
      console.log("<<<<<<<UPDATE STUDENT>>>>>>>>", data.statusText);
    },
    updateSellerError(err) {
      console.log("<<<<<<<UPDATE STUDENT>>>>>>>>", err);
    },
  };

  const presenter = getSellerUpdatePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getSellerToUpd(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSeller = (data) => {
    presenter.updateSeller(data);
  };

  return (
    <div className="seller-edit-main-container">
      <div className="seller-edit-title-container">
        <div className="seller-edit-title">Actualizar Vendedor</div>
      </div>
      <hr className="divider" />
      <div className="seller-edit-form-container">
        <SellerForm update={updateSeller} seller={seller} idSeller={id} />
      </div>
    </div>
  );
};

export default SellerEdit;
