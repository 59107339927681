import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../../../../context/CartContext";
import { Controller, useForm } from "react-hook-form";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const SalesForm = ({ createSale, clearCart }) => {
  const { product } = useContext(CartContext);
  const navigate = useNavigate();
  const [pressed, setPressed] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      course_id: null,
      document_number: "",
      email: "",
      referral_code: "",
      pay_first_installment: false,
      uses_tools: false,
    },
  });

  const onChangeSwitch = (e) => {
    setValue("uses_tools", e.target.checked);
  };

  const setPayFirstInstallment = (e) => {
    setValue("pay_first_installment", e.target.checked);
  };

  const goBack = () => {
    clearCart();
    navigate(-1);
  };

  const onSubmitForm = (data) => {
    setPressed(true);
    createSale(data);
  };

  useEffect(() => {
    if (product) {
      setValue("course_id", product.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sales-form-main-container">
      <div className="sales-form-title-container">
        <h2 className="sales-form-title">Unos últimos datos...</h2>
      </div>
      <Container fluid className="sales-form-grid-container">
        <Form onSubmit={handleSubmit(onSubmitForm)} className="sales-form">
          {/* email */}
          <Row className="sales-form-grid-row">
            <Form.Group
              as={Col}
              className="sales-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sales-form-input-label">Email</Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="email"
                      placeholder="username@example.com"
                      isInvalid={errors.email}
                      className="sales-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.email && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto sales-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* document_number */}
          <Row className="sales-form-grid-row">
            <Form.Group
              as={Col}
              className="sales-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sales-form-input-label">DNI</Form.Label>
              <Controller
                name="document_number"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="00000000"
                      isInvalid={errors.document_number}
                      className="sales-form-input"
                    />
                  </Stack>
                )}
              />
              {errors.document_number && (
                <Stack
                  direction="horizontal"
                  className="col-md-12 mx-auto sales-form-input-error"
                >
                  *Este campo es obligatorio
                </Stack>
              )}
            </Form.Group>
          </Row>

          {/* referral_code */}
          <Row className="sales-form-grid-row">
            <Form.Group
              as={Col}
              className="sales-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Form.Label className="sales-form-input-label">
                Código de descuento
              </Form.Label>
              <Controller
                name="referral_code"
                control={control}
                render={({ field }) => (
                  <Stack direction="horizontal" className="col-md-12 mx-auto">
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="example.code"
                      isInvalid={errors.referral_code}
                      className="sales-form-input"
                    />
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>

          {/* uses_tools */}
          <Row className="sales-form-grid-row">
            <Form.Group
              as={Col}
              className="sales-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Controller
                name="uses_tools"
                control={control}
                render={({ field }) => (
                  <Stack
                    direction="horizontal"
                    className="col-md-12 mx-auto mt-4"
                  >
                    <Form.Check
                      id="uses_tools"
                      type="switch"
                      className="sales-form-uses-tools"
                    >
                      <Form.Check.Input
                        className="sales-check-switch"
                        onChange={(e) => onChangeSwitch(e)}
                      />
                      <Form.Check.Label className="sales-form-input-label">
                        Utilizo mis propias herramientas (máquina de corte,
                        patillera, tijeras y rasuradora)
                      </Form.Check.Label>
                    </Form.Check>
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>

          {/* pay_first_installment */}
          <Row className="sales-form-grid-row">
            <Form.Group
              as={Col}
              className="sales-form-grid-item"
              xs={12}
              md={12}
              lg={12}
            >
              <Controller
                name="pay_first_installment"
                control={control}
                render={({ field }) => (
                  <Stack
                    direction="horizontal"
                    className="col-md-12 mx-auto mt-4"
                  >
                    <Form.Check
                      id="pay_first_installment"
                      type="switch"
                      className="sales-form-first-installment"
                    >
                      <Form.Check.Input
                        className="sales-first-installment-check-switch"
                        onChange={(e) => setPayFirstInstallment(e)}
                      />
                      <Form.Check.Label className="sales-form-input-label">
                        Pagar solo la primera cuota
                      </Form.Check.Label>
                    </Form.Check>
                  </Stack>
                )}
              />
            </Form.Group>
          </Row>

          <div className="sales-form-submit-btn-container">
            <button
              onClick={goBack}
              type="button"
              className="sales-form-back-btn"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={
                !pressed
                  ? "sales-form-submit-btn"
                  : "sales-form-submit-btn-disabled"
              }
              disabled={pressed}
            >
              Confirmar
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default SalesForm;
