import axios from "axios";

export const HttpGetAllCoursesGateway = () => {
  return {
    getAllCourses: async () => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/business/course/get-all`
      );
    },
  };
};
