import { Font, StyleSheet } from "@react-pdf/renderer";
import AntonioBold from "./../../../../assets/fonts/Antonio-Bold.ttf";
import AntonioMedium from "./../../../../assets/fonts/Antonio-Medium.ttf";
import AntonioThin from "./../../../../assets/fonts/Antonio-Thin.ttf";

Font.register({
  family: "Antonio-Bold",
  src: AntonioBold,
});
Font.register({
  family: "Antonio-Medium",
  src: AntonioMedium,
});
Font.register({
  family: "Antonio-Thin",
  src: AntonioThin,
});

export const styles = StyleSheet.create({
  body: {
    border: "5px solid #D9D9D9",
    backgroundColor: "black",
  },
  //   HEADER
  carnetPDFHeaderContainer: {
    width: "100%",
    backgroundColor: "black",
  },
  carnetPDFTitleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  carnetPdfHeaderTitle: {
    width: "100%",
    textAlign: "center",
    marginTop: "3px",
    fontSize: 10,
    fontFamily: "Antonio-Bold",
  },
  carnetPDFSubtitleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  carnetPdfHeaderSubtitle: {
    color: "yellow",
    textDecorationColor: "yellow",
    fontSize: 8,
    fontFamily: "Antonio-Bold",
  },
  //   BODY
  carnetPDFBodyContainer: {
    width: "100%",
    backgroundColor: "#FFFAFA",
    flexDirection: "column",
  },
  carnetPDFStudentDivisor: {
    flexDirection: "row",
  },
  //Mitad 1
  carnetPDFStudentDataContainer: {
    flex: 1,
    margin: 2,
  },
  //view foto
  carnetPDFStudentImageContainer: {
    margin: 8,
  },
  carnetPDFProfileImage: {
    margin: 5,
  },
  //view data
  carnetPDFStudentPersonalDataContainer: {
    margin: 2,
    marginLeft: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  carnetPDFStudentPersonalDataLabel: {
    width: "100%",
    margin: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 8,
    fontFamily: "Antonio-Thin",
  },
  carnetPDFStudentPersonalDataContent: {
    fontSize: 8,
    fontFamily: "Antonio-Bold",
  },
  //Mitad 2
  carnetPDFStudentCoursesContainer: {
    flex: 1,
    margin: 2,
  },
  carnetPDFStudentCoursesLabel: {
    marginTop: 1,
    fontSize: 8,
    fontFamily: "Antonio-Bold",
  },
  carnetPDFStudentCoursesData: {
    marginTop: 3,
    marginBottom: 2,
    height: "100%",
    fontSize: 7,
    fontFamily: "Antonio-Medium",
  },

  //   FOOTER
  carnetPDFFooterContainer: {
    width: "100%",
    height: "10%",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
  },
  carnetPDFFooterTitleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "yellow",
  },
  carnetPdfFooterTitle: {
    fontSize: 8,
    fontFamily: "Antonio-Bold",
  },
});