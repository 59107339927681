/* Gateways */
import { HttpGetAllCareersGateway } from "../gateways/HttpGetAllCareersGateway";
import { HttpCreateCareerGateway } from "../gateways/HttpCreateCareerGateway";
import { HttpDeleteCareerGateway } from "../gateways/HttpDeleteCareerGateway";
import { HttpGetCareerGateway } from "../gateways/HttpGetCareerGateway";
import { HttpUpdateCareerGateway } from "../gateways/HttpUpdateCareerGateway";

/* Presenters */
import { CareersHomePresenter } from "./CareersHomePresenter";
import { CareersCreatePresenter } from "./CareersCreatePresenter";
import { CareersDeletePresenter } from "./CareersDeletePresenter";
import { CareersUpdatePresenter } from "./CareersUpdatePresenter";
import { CareersDetailPresenter } from "./CareersDetailPresenter";

export const getCareersHomePresenter = (viewHandlers) => {
  const getHttpGetAllCareersGateway = HttpGetAllCareersGateway();
  return CareersHomePresenter(getHttpGetAllCareersGateway, viewHandlers);
};

export const getCareersCreatePresenter = (viewHandlers) => {
  const getHttpCreateCareerGateway = HttpCreateCareerGateway();
  return CareersCreatePresenter(getHttpCreateCareerGateway, viewHandlers);
};

export const getCareersDeletePresenter = (viewHandlers) => {
  const getHttpDeleteCareerPresenter = HttpDeleteCareerGateway();
  return CareersDeletePresenter(getHttpDeleteCareerPresenter, viewHandlers);
};

export const getCareersDetailPresenter = (viewHandlers) => {
  const getHttpGetCareerGateway = HttpGetCareerGateway();
  return CareersDetailPresenter(getHttpGetCareerGateway, viewHandlers);
};

export const getCareersUpdatePresenter = (viewHandlers) => {
  const getHttpGetCareerGateway = HttpGetCareerGateway();
  const getHttpUpdateCareerGateway = HttpUpdateCareerGateway();
  return CareersUpdatePresenter(
    getHttpGetCareerGateway,
    getHttpUpdateCareerGateway,
    viewHandlers
  );
};
