import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./ButtonWsp.scss";

const ButtonWsp = () => {
  return (
    <>
      <a
        href="https://wa.me/2614709741?text=Hola!!%20Quiero%20información%20sobre%20la%20academia!"
        className="buttonWsp"
        rel="noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          style={{ background: "transparent" }}
        />
      </a>
    </>
  );
};

export default ButtonWsp;
