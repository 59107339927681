import axios from "axios";

export const HttpStudentLoginGateway = () => {
  return {
    logIn: async (data) => {
      const { email, password } = data;
      const body = {
        email,
        password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/business/student/login`,
        body
      );
    },
  };
};
